<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.55476 6.17188H7.51715L7.51696 6.81578L7.43326 6.84743C6.94906 7.04689 6.65404 7.46657 6.65404 7.98296C6.65404 8.62299 7.04375 8.97421 7.81118 9.26942L7.93625 9.32231C8.17853 9.43208 8.24041 9.51343 8.24041 9.62238C8.24041 9.74597 8.1117 9.83206 7.90409 9.83206C7.68755 9.83206 7.44696 9.76086 7.27301 9.64566L6.91042 9.40463L6.53716 10.4304L6.74836 10.5788L6.82148 10.6262C6.999 10.733 7.22332 10.8127 7.46056 10.8542L7.47715 10.8563L7.4772 11.4531H8.51879L8.51877 10.7926L8.60072 10.7618C9.10438 10.5531 9.42187 10.109 9.42187 9.58306C9.42187 8.93401 9.07258 8.56143 8.41498 8.27901L8.32746 8.24267C8.27678 8.22189 8.23109 8.20259 8.18993 8.18454L8.07957 8.13393C7.88419 8.03939 7.83549 7.98225 7.83549 7.91613C7.83549 7.83151 7.90135 7.77329 8.09989 7.77329C8.26369 7.77329 8.3995 7.80387 8.52219 7.85693L8.57923 7.88355L8.6492 7.92105L8.99296 8.11432L9.37768 7.10494L9.1302 6.95885L9.04438 6.91221C8.8994 6.83878 8.74443 6.78668 8.57573 6.7537L8.55452 6.74997L8.55476 6.17188Z" fill="#1C8C3D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5 2.10938H1.5V5.35938H2.10938V13.8906H13.8906V5.35938H14.5V2.10938ZM13.2812 4.14062H2.71875V3.32812H13.2812V4.14062Z"
      fill="#1C8C3D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.633 5.8125H7.45418L7.45396 6.54404L7.35887 6.58C6.80877 6.8066 6.4736 7.28339 6.4736 7.87007C6.4736 8.5972 6.91635 8.99622 7.78823 9.33161L7.93031 9.39169C8.20556 9.5164 8.27587 9.60883 8.27587 9.7326C8.27587 9.87301 8.12965 9.97081 7.89378 9.97081C7.64777 9.97081 7.37443 9.88992 7.17681 9.75905L6.76488 9.48521L6.34082 10.6505L6.58076 10.8192L6.66383 10.873C6.86551 10.9944 7.12035 11.0849 7.38988 11.1321L7.40873 11.1345L7.40879 11.8125H8.59214L8.59212 11.062L8.68521 11.0271C9.25742 10.79 9.61812 10.2855 9.61812 9.68793C9.61812 8.95055 9.22129 8.52726 8.47419 8.2064L8.37476 8.16511C8.31719 8.14151 8.26528 8.11958 8.21852 8.09907L8.09314 8.04158C7.87118 7.93417 7.81585 7.86926 7.81585 7.79414C7.81585 7.69801 7.89067 7.63185 8.11623 7.63185C8.30232 7.63185 8.45661 7.6666 8.59599 7.72688L8.6608 7.75713L8.7403 7.79973L9.13084 8.01931L9.56792 6.87255L9.28675 6.70658L9.18926 6.65359C9.02455 6.57017 8.84848 6.51097 8.65683 6.47351L8.63273 6.46927L8.633 5.8125Z"
      fill="#FFF8E7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.633 5.8125H7.45418L7.45396 6.54404L7.35887 6.58C6.80877 6.8066 6.4736 7.28339 6.4736 7.87007C6.4736 8.5972 6.91635 8.99622 7.78823 9.33161L7.93031 9.39169C8.20556 9.5164 8.27587 9.60883 8.27587 9.7326C8.27587 9.87301 8.12965 9.97081 7.89378 9.97081C7.64777 9.97081 7.37443 9.88992 7.17681 9.75905L6.76488 9.48521L6.34082 10.6505L6.58076 10.8192L6.66383 10.873C6.86551 10.9944 7.12035 11.0849 7.38988 11.1321L7.40873 11.1345L7.40879 11.8125H8.59214L8.59212 11.062L8.68521 11.0271C9.25742 10.79 9.61812 10.2855 9.61812 9.68793C9.61812 8.95055 9.22129 8.52726 8.47419 8.2064L8.37476 8.16511C8.31719 8.14151 8.26528 8.11958 8.21852 8.09907L8.09314 8.04158C7.87118 7.93417 7.81585 7.86926 7.81585 7.79414C7.81585 7.69801 7.89067 7.63185 8.11623 7.63185C8.30232 7.63185 8.45661 7.6666 8.59599 7.72688L8.6608 7.75713L8.7403 7.79973L9.13084 8.01931L9.56792 6.87255L9.28675 6.70658L9.18926 6.65359C9.02455 6.57017 8.84848 6.51097 8.65683 6.47351L8.63273 6.46927L8.633 5.8125Z"
      fill="#FFF8E7"
    />
    <mask
      id="mask0_492_21114"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="14"
      height="12"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5 2.10938H1.5V5.35938H2.10938V13.8906H13.8906V5.35938H14.5V2.10938ZM13.2812 4.14062H2.71875V3.32812H13.2812V4.14062Z"
        fill="#4B0303"
      />
    </mask>
    <g mask="url(#mask0_492_21114)">
      <circle
        cx="0.450391"
        cy="1.14961"
        r="8.55"
        fill="white"
        fill-opacity="0.2"
      />
    </g>
  </svg>
</template>
