export function highlight({
  target = null,
  elements = [],
  options = {
    stagePadding: [7, 4],
    stageRadius: 4,
    opacity: 0.8,
    fill: 'rgb(0,0,0)',
  },
}) {
  const getConfig = (config) => {
    return options[config]
  }
  const getStagePosition = () => {
    const stagePosition = []
    elements.forEach((element) => {
      const definition = element.getBoundingClientRect()
      const activeStagePosition = {
        x: definition.x,
        y: definition.y,
        width: definition.width,
        height: definition.height,
      }
      stagePosition.push(activeStagePosition)
    })
    return stagePosition
  }

  const create = () => {
    if (elements.length === 0 || !target) {
      return
    }
    const stagePosition = getStagePosition()
    const overlaySvg = createOverlaySvg(stagePosition)
    target.appendChild(overlaySvg)
  }

  const createOverlaySvg = (stages) => {
    const windowX = window.innerWidth
    const windowY = window.innerHeight

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.classList.add('driver-overlay', 'driver-overlay-animated')

    svg.setAttribute('viewBox', `0 0 ${windowX} ${windowY}`)
    svg.setAttribute('xmlSpace', 'preserve')
    svg.setAttribute('xmlnsXlink', 'http://www.w3.org/1999/xlink')
    svg.setAttribute('version', '1.1')
    svg.setAttribute('preserveAspectRatio', 'xMinYMin slice')

    svg.style.fillRule = 'evenodd'
    svg.style.clipRule = 'evenodd'
    svg.style.strokeLinejoin = 'round'
    svg.style.strokeMiterlimit = '2'
    svg.style.position = 'fixed'
    svg.style.top = '0'
    svg.style.left = '0'
    svg.style.width = '100%'
    svg.style.height = '100%'

    // 遍历 stages，将所有路径拼接到一个路径中
    const mergedPathString = stages.map((item) => generateStageSvgPathString(item)).join(' ')

    const singlePath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    singlePath.setAttribute(
      'd',
      `M${windowX},0L0,0L0,${windowY}L${windowX},${windowY}L${windowX},0Z ${mergedPathString}`,
    )

    singlePath.style.fill = getConfig('fill')
    singlePath.style.opacity = getConfig('opacity')
    singlePath.style.pointerEvents = 'auto'
    singlePath.style.cursor = 'auto'

    svg.appendChild(singlePath)

    return svg
  }

  const generateStageSvgPathString = (stage) => {
    const windowX = window.innerWidth
    const windowY = window.innerHeight

    const stagePadding = getConfig('stagePadding') || [7, 4]
    const stageRadius = getConfig('stageRadius') || 4

    const stageWidth = stage.width + stagePadding[0] * 2
    const stageHeight = stage.height + stagePadding[1] * 2

    // prevent glitches when stage is too small for radius
    const limitedRadius = Math.min(stageRadius, stageWidth / 2, stageHeight / 2)

    // no value below 0 allowed + round down
    const normalizedRadius = Math.floor(Math.max(limitedRadius, 0))

    const highlightBoxX = stage.x - stagePadding[0] + normalizedRadius
    const highlightBoxY = stage.y - stagePadding[1]
    const highlightBoxWidth = stageWidth - normalizedRadius * 2
    const highlightBoxHeight = stageHeight - normalizedRadius * 2

    return `M${windowX},0L0,0L0,${windowY}L${windowX},${windowY}L${windowX},0Z
      M${highlightBoxX},${highlightBoxY} h${highlightBoxWidth} a${normalizedRadius},${normalizedRadius} 0 0 1 ${normalizedRadius},${normalizedRadius} v${highlightBoxHeight} a${normalizedRadius},${normalizedRadius} 0 0 1 -${normalizedRadius},${normalizedRadius} h-${highlightBoxWidth} a${normalizedRadius},${normalizedRadius} 0 0 1 -${normalizedRadius},-${normalizedRadius} v-${highlightBoxHeight} a${normalizedRadius},${normalizedRadius} 0 0 1 ${normalizedRadius},-${normalizedRadius} z`
  }

  create()
}
