<template>
  <div>
    <div
      v-if="realtimeBehaviorLabels.length"
      class="cart-labels"
    >
      <RealtimeType
        :labels="realtimeBehaviorLabels"
        :ele-info="{
          'headerEle': '.j-cart-header .j-cart-nav',
        }"
        :title-nav-style-from-ccc="titleNavStyleFromCcc"
      >
        <template #left>
          <CartNavAll
            v-if="cartBottomStyle"
            marginRight="0.32rem"
          />
        </template>
        <template #right>
          <CartNavOperation v-if="cartBottomStyle" />
        </template>
      </RealtimeType>
    </div>
    
    <div v-show="!batchActive">
      <CartNotice 
        v-if="cartNoticeMessage"
        :message="cartNoticeMessage"
        :language="language"
      />
      <template v-if="showUnpaidOrderTip">
        <UnpaidOrderTipV2
          v-if="isShowNewUnpaidOrder"
          :unpaid-order-info="unpaidOrderInfo"
          :language="language"
          :locals="locals"
        />
        <UnpaidOrderTip
          v-else
          :unpaid-order-info="unpaidOrderInfo"
          :language="language"
          :locals="locals"
        />
      </template>
      <CanceledOrderTip
        v-if="showCanceledOrderTip"
        :canceled-order-info="canceledOrderInfo"
        :language="language"
      />
      <CartTopRights
        v-if="showCartRights && topRightsFuns.isShowNewTopRights"
        :cart-rights="topRightsFuns"
      />
      <CartTopFreeShippingActivity
        v-if="showCartTopShipping"
        :style="{
          '--shipping-info-background': showCartRights ? 'rgba(255, 243, 211, 1)' : '',
        }"
      />
      <CartQuickShipTop
        v-if="showQuickShipOnTop"
        :cart-qs-tag-info="cartQsTagInfo"
      />
      <!-- 未登陆提示 -->
      <div
        v-if="showLogin"
        v-expose="{id: '1-8-7-2'}"
        v-tap="{id: '1-8-1-8'}"
        @click="clickLogin('cart')"
      >
        <s-alert type="info">
          {{ language.SHEIN_KEY_PWA_14964 }}
          <template #operation>
            <s-button
              :type="['H48PX', 'primary']"
            >
              {{ language.SHEIN_KEY_PWA_15578 }}
            </s-button>
          </template>
        </s-alert>
      </div>

      <!-- 有其他任何置顶的元素（除了[未登陆提示]），新品推荐 tip 都要隐藏 -->
      <NewGoodsRecommendTip v-if="showNewGoodsRecommendStyleB && !isFilterWishlist && !cartNoticeMessage && !showUnpaidOrderTip && !showCanceledOrderTip && !(showCartRights && topRightsFuns.isShowNewTopRights) && !showCartTopShipping && !showQuickShipOnTop" />

      <NewGoodsRecommendToast v-if="showNewGoodsRecommendStyleA" />
    </div>
    <ClientOnly>
      <s-network-error-alert :description="language.SHEIN_KEY_PWA_31743" />
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartTop',
})
</script>
<script setup>
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { vExpose, vTap } from 'public/src/pages/common/analysis/directive'
import { computed, watch } from 'vue'
import RealtimeType from 'public/src/pages/cart_v2/components/cartRealtimeBehaviorLable/RealtimeType.vue'
import CartNotice from 'public/src/pages/cart_v2/components/cartNotice/index.vue'
import UnpaidOrderTip from 'public/src/pages/cart_v2/components/unpaidOrderTip/UnpaidOrderTip.vue'
import UnpaidOrderTipV2 from 'public/src/pages/cart_v2/components/unpaidOrderTip/UnpaidOrderTipV2.vue'
import NewGoodsRecommendTip from 'public/src/pages/cart_v2/components/cartNewGoodsRecommend/NewGoodsRecommendTip.vue'
import NewGoodsRecommendToast from 'public/src/pages/cart_v2/components/cartNewGoodsRecommend/NewGoodsRecommendToast.vue'
import CartTopRights from 'public/src/pages/cart_v2/components/cartRights/index.vue'
import CartTopFreeShippingActivity from 'public/src/pages/cart_v2/components/cartFreeShippingActivity/index.vue'
import CartQuickShipTop from 'public/src/pages/cart_v2/components/cartQuickShip/index.vue'
import { useStore } from '../../hooks/useStore'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SNetworkErrorAlert } from '@shein-aidc/sui-network-error-alert/mobile'
import CanceledOrderTip from 'public/src/pages/cart_v2/components/canceledOrderTip/CanceledOrderTip.vue'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { NEW_GOODS_RECOMMENDED } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import CartNavAll from 'public/src/pages/cart_v2/components/cartHeader/CartNavAll.vue'
import CartNavOperation from 'public/src/pages/cart_v2/components/cartHeader/CartNavOperation.vue'

const { state, getters } = useStore()
const { updateAsyncPopoverState, registerPopover } = usePopoverManager({ type: NEW_GOODS_RECOMMENDED, isAsync: true })
registerPopover()

const emit = defineEmits(['login-handle'])
const cartBottomStyle = computed(() => getters.cartBottomStyle)
const realtimeBehaviorLabels = computed(() =>state.carouselState?.usefulLabels)
const cartNoticeMessage = computed(() => state.cartNoticeMessage)
const language = computed(() => state.language)
const topRightsFuns = computed(() => state.topRightsFuns)
const unpaidOrderInfo = computed(() => state.orderState.unpaidOrderInfo)
const canceledOrderInfo = computed(() => state.orderState.canceledOrderInfo)
const locals = computed(() => state.locals)
const batchActive = computed(() => state.batchActive)

const showNewGoodsRecommendStyleA = computed(() => getters['showNewGoodsRecommendStyleA'])
const showNewGoodsRecommendStyleB = computed(() => getters['showNewGoodsRecommendStyleB'])
const isFilterWishlist = computed(() => getters.isFilterWishlist)

const showCartRights = computed(() => getters['showCartRights'])
const showCartTopShipping = computed(() => {
  if(topRightsFuns.value.freeShipping || topRightsFuns.value.oldUserType > 0) {
    return false
  }
  return getters['cartShippingTop']
})
// 是否展示非空车状态待支付订单
const showUnpaidOrderTip = computed(() => {
  const { carts, loginStatus } = state
  return unpaidOrderInfo.value && loginStatus && carts.length > 0
})
watch(() => showNewGoodsRecommendStyleA.value, () => {
  updateAsyncPopoverState()
}, {
  immediate: true
})
watch(() => showUnpaidOrderTip.value, (val) => {
  if(typeof window == 'undefined') return
  if(val) {
    const benefitList = []
    if(unpaidOrderInfo.value?.savedPrice?.amount > 0) {
      benefitList.push('discount')
    }
    if (unpaidOrderInfo.value?.benefitBiData) {
      benefitList.push(unpaidOrderInfo.value.benefitBiData)
    }
    exposeScenesAbtAggregator.addEvent({
      daId: '1-8-7-60',
      extraData: {
        scenes: 'cartUnpaidOrder',
        order_id: unpaidOrderInfo.value?.billno,
        benefit_list: benefitList.join('`') || 'common',
      }
    })
  }
}, { immediate: true })

watch(() => realtimeBehaviorLabels.value, (n, o) => {
  if(typeof window == 'undefined') return
  if(n && o && n.length == 0 && o.length > 0) {
    const headerElDom = document.querySelector('.j-cart-header .j-cart-nav')
    const headerElHeight = headerElDom?.getBoundingClientRect()?.height || 0
    if(headerElHeight <= 0 && headerElDom) {
      headerElDom.style.border = ''
      headerElDom.style.opacity = 1
      headerElDom.style.height = '1.1733rem'
    }
  }
})

const isShowNewUnpaidOrder = computed(() => !!unpaidOrderInfo.value?.benefitInfo)

const showCanceledOrderTip = computed(() => {
  const { carts, loginStatus } = state
  return canceledOrderInfo.value && loginStatus && carts.length > 0
})

const cartQsTagInfo = computed(() => {
  return state.topBannerInfo || {}
})

// 购物车顶部qs信息栏展示条件:
// 开启quickship标签展示abt && qs时效信息不为空 && 非空车
const showQuickShipOnTop = computed(() => {
  return (
    getters['showQuickShipTag'] &&
    cartQsTagInfo.value?.view?.text &&
    state.cartInfo?.allProductLineSumQuantity > 0
  )
})

const showLogin = computed(() => {
  let { batchActive, loginStatus, cartInfo } = state
  return !batchActive && !loginStatus && cartInfo?.allProductLineSumQuantity > 0
})

const titleNavStyleFromCcc = computed(() => state.titleNavStyleFromCcc)

const clickLogin = (type) => {
  emit('login-handle', type)
}
</script>
<style lang='less' scoped>
.cart-labels {
  margin-top: -1/37.5rem;
  .cart-nav__operation {
    flex: 1;
  }
}
</style>
