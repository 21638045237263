import { ref, onMounted, computed } from 'vue'
import { useStore } from '../../../hooks/useStore.js'
import newNavBarGuide from './newNavBarGuide.js'
import { getCache, setCache } from '../cache.js'

export const useNewNavBarGuide = () => {
  const CACHE_KEY = 'newNavBarGuideShow'

  const { state, getters } = useStore()

  const newNavBarGuideShow = ref(false)
  const allProductLineSumQuantity = computed(() => state.cartInfo.allProductLineSumQuantity)

  const onNewNavBarGuideClose = () => {
    newNavBarGuideShow.value = false
    newNavBarGuide.end()
  }

  onMounted(() => {
    newNavBarGuide.initialize({
      start() {
        const res = getters['cartBottomStyle'] && allProductLineSumQuantity.value > 0 
        if (res) {
          newNavBarGuideShow.value = true
        } else {
          // 不满足展示引导条件 跳过引导
          newNavBarGuide.skip()
        }
      },
      close() {
        setCache(CACHE_KEY, true)
      },
      isCompleted() {
        if (typeof window === 'undefined') return true
        return !!getCache(CACHE_KEY) || !(getters['cartBottomStyle'] && allProductLineSumQuantity.value > 0)
      },
    })
  })

  return {
    newNavBarGuideShow,
    onNewNavBarGuideClose,
  }
}
