<template>
  <div class="checkout-filter-popover">
    <s-popover
      v-model="showPopoverState"
      trigger="user"
      placemen="top-end"
      :show-close-icon="false"
      :popup-style="popupStyle"
    >
      <span>{{ language.SHEIN_KEY_PWA_23995 }}</span>
      <template #reference>
        <div
          :class="[
            'checkout-btn-contain',
          ]"
        >
          <slot></slot>
        </div>
      </template>
    </s-popover>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckoutFilterPopover',
})
</script>
<script setup>
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { computed, defineEmits, watch } from 'vue'
import { useStore } from '../../hooks/useStore'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { BOTTOM_COUPON_FILTER_CHECKOUT } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'

const { state } = useStore()
const { isActivePopover, activatePopover, deactivatePopover, registerPopover } = usePopoverManager({ type: BOTTOM_COUPON_FILTER_CHECKOUT })
registerPopover()

const props = defineProps({
  showFilterLabelPopover: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['set-popover-type'])
const language = computed(() => state.language)
const popupStyle = computed(() => {
  return {
    position: 'fixed',
    top: 'unset',
    bottom: '64px',
  }
})

const showCheckoutBtnPopover = computed(() => props.showFilterLabelPopover == 'filter')
const showPopoverState = computed({
  get() {
    return showCheckoutBtnPopover.value && isActivePopover.value
  },
  set(val) {
    if(!val) emit('set-popover-type', '')
  }
})

watch(() => showCheckoutBtnPopover.value, (val) => {
  if(val) {
    activatePopover()
  }
})
watch(() => showPopoverState.value, (val) => {
  if(!val) {
    deactivatePopover()
  }
})

</script>

