<template>
  <div
    ref="root"
    class="cart-common-text-ellipsis"
    :style="style"
  >
    <span
      v-if="slots.start"
      ref="startRef"
      class="cart-common-text-ellipsis__start"
    ><slot name="start"></slot></span>{{ text }}<span
      v-if="slots.end"
      ref="endRef"
      class="cart-common-text-ellipsis__end"
    ><slot name="end"></slot></span>
  </div>
</template>

<script setup>
import { ref, useSlots, onMounted, nextTick, onActivated, watch, onUnmounted, onDeactivated } from 'vue'
import { debounce } from '@shein/common-function'
const props = defineProps({
  rows: {
    type: Number,
    default: 1
  },
  content: {
    type: String,
    default: ''
  },
  dots: {
    type: String,
    default: '...'
  },
  position: {
    type: String,
    default: 'end'
  },
  // eslint-disable-next-line vue/require-default-prop
  minSize: {
    type: Number,
  },
  step: {
    type: Number,
    default: 1
  },
  // eslint-disable-next-line vue/require-default-prop
  maxHeight: {
    type: Number,
  },
  observerDOM: {
    type: Boolean,
    default: false
  }
})
const slots = useSlots()
const style = ref({})

let needRecalculate = false

const pxToNum = (value) => {
  if (!value) return 0
  if (typeof value === 'number') return value
  const match = value.match(/^\d*(\.\d*)?/)
  return match ? Number(match[0]) : 0
}
const clone = (node) => {
  if (!node) return
  const cloneNode = node.cloneNode()
  const originStyle = window.getComputedStyle(node)
  const styleNames = Array.prototype.slice.apply(originStyle)

  styleNames.forEach((name) => {
    if (name === 'vertical-align')  {
      cloneNode.style.setProperty(name, 'top')
    } else {
      cloneNode.style.setProperty(name, originStyle.getPropertyValue(name))
    }
  })
  return cloneNode
}

const text = ref(props.content)
const root = ref()
const startRef = ref()
const endRef = ref()
const cloneContainer = () => {
  if (!root.value || !root.value.isConnected) return
  
  const container = clone(root.value)
  const customContainerStyles = {
    position: 'fixed',
    zIndex: '-9999',
    top: '-9999px',
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 'auto',
    visibility: 'hidden',
  }
  Object.keys(customContainerStyles).forEach((key) => {
    container.style.setProperty(key, customContainerStyles[key])
  })

  const startClone = clone(startRef?.value)
  const endClone = clone(endRef?.value)
  const contentNode = document.createTextNode(props.content)
  if (startClone) container.appendChild(startClone)
  container.appendChild(contentNode)
  if (endClone) container.appendChild(endClone)

  document.body.appendChild(container)
  return {
    container,
    contentNode,
  }
}

// 缓存测量结果
const lineHeightCache = new WeakMap()
const getRealLineHeight = (element) => {
  if (lineHeightCache.has(element)) {
    return lineHeightCache.get(element)
  }
  // 方法1：直接获取计算值（现代浏览器适用）
  const computed = window.getComputedStyle(element).lineHeight
  
  // 如果已经是像素值直接返回
  if (computed.endsWith('px')) {
    return parseFloat(computed)
  }

  // 方法2：通过临时元素测量（兼容方案）
  const temp = document.createElement('div')
  temp.style.cssText = `
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    font-family: ${window.getComputedStyle(element).fontFamily};
    font-size: ${window.getComputedStyle(element).fontSize};
    line-height: normal;
  `
  
  temp.innerHTML = '<span>M</span><br><span>M</span>' // 创建两行内容
  document.body.appendChild(temp)
  
  // 计算行高
  const height = temp.offsetHeight
  const lineHeight = Math.round(height / 2)
  document.body.removeChild(temp)

  lineHeightCache.set(element, lineHeight)
  return lineHeight
}

const calcEllipsisText = ({ container, contentNode, maxHeight }) => {
  const { content, position, dots } = props
  const end = content.length
  const middle = (0 + end) >> 1

  const calcEllipse = () => {
    // calculate the former or later content
    const tail = (left, right) => {
      if (right - left <= 1) {
        if (position === 'end') {
          return content.slice(0, left) + dots
        }
        return dots + content.slice(right, end)
      }

      const middle = Math.round((left + right) / 2)

      // Set the interception location
      if (position === 'end') {
        contentNode.textContent = content.slice(0, middle) + dots
      } else {
        contentNode.textContent = dots + content.slice(middle, end)
      }
      // The height after interception still does not match the rquired height
      if (container.offsetHeight > maxHeight) {
        if (position === 'end') {
          return tail(left, middle)
        }
        return tail(middle, right)
      }

      if (position === 'end') {
        return tail(middle, right)
      }

      return tail(left, middle)
    }

    return tail(0, end)
  }

  const middleTail = (
    leftPart,
    rightPart,
  ) => {
    if (
      leftPart[1] - leftPart[0] <= 1 &&
          rightPart[1] - rightPart[0] <= 1
    ) {
      return (
        content.slice(0, leftPart[0]) +
            dots +
            content.slice(rightPart[1], end)
      )
    }

    const leftMiddle = Math.floor((leftPart[0] + leftPart[1]) / 2)
    const rightMiddle = Math.ceil((rightPart[0] + rightPart[1]) / 2)

    contentNode.textContent =
          props.content.slice(0, leftMiddle) +
          props.dots +
          props.content.slice(rightMiddle, end)

    if (container.offsetHeight >= maxHeight) {
      return middleTail(
        [leftPart[0], leftMiddle],
        [rightMiddle, rightPart[1]],
      )
    }

    return middleTail(
      [leftMiddle, leftPart[1]],
      [rightPart[0], rightMiddle],
    )
  }

  return props.position === 'middle'
    ? middleTail([0, middle], [middle, end])
    : calcEllipse()
}
const calcEllipsised = () => {
  const { container, contentNode } = cloneContainer()
  if (!container) {
    needRecalculate = true
    return
  }
  const { paddingBottom, paddingTop } = container.style
  const lineHeight = getRealLineHeight(container) 
  const maxHeight = Math.ceil((Number(props.rows) + 0.5) * pxToNum(lineHeight) + pxToNum(paddingTop) + pxToNum(paddingBottom))
  if (maxHeight < container.offsetHeight) {
    text.value = calcEllipsisText({ container, contentNode, maxHeight })
  } else {
    text.value = props.content
  }

  document.body.removeChild(container)
}

const scaleText = ({ container, maxHeight, fontSize, minSize, step }) => {
  const currentSize = pxToNum(fontSize)
  const tail = (currentSize) => {
    currentSize = currentSize < minSize ? minSize : currentSize
    container.style.setProperty('font-size', `${currentSize}px`)
    if (currentSize <= minSize) {
      if (container.offsetHeight > maxHeight) {
        return { meet: false, size: currentSize }
      }
      return { meet: true, size: currentSize }
    }
    if (container.offsetHeight > maxHeight) {
      return tail(currentSize - step)
    }
    return { meet: true, size: currentSize }
  }
  return tail(currentSize)
}
const scale = () => {
  const { container } = cloneContainer()
  if (!container) return

  const { paddingBottom, paddingTop, fontSize } = container.style
  let maxHeight
  if (props.maxHeight) {
    maxHeight = props.maxHeight
  } else {
    const originStyle = window.getComputedStyle(root.value)
    const rootMaxHeight = pxToNum(originStyle.getPropertyValue('max-height')) || pxToNum(originStyle.getPropertyValue('height'))

    const lineHeight = getRealLineHeight(container)
    const rowMaxHeight = Math.ceil((Number(props.rows) + 0.5) * pxToNum(lineHeight) + pxToNum(paddingTop) + pxToNum(paddingBottom))
    
    maxHeight = Math.min(rootMaxHeight, rowMaxHeight)
  }
  let result = { meet: true, size: fontSize }
  if (maxHeight < container.offsetHeight) {
    result = scaleText({ container, maxHeight, fontSize, minSize: props.minSize, step: props.step })
  }

  document.body.removeChild(container)
  return result
}

const reset = () => {
  text.value = props.content
  style.value = {}
}
const handler = debounce({
  func: async () => {
    reset()
    await nextTick()
    let result
    if (props.minSize) {
      result = scale()
      style.value = {
        fontSize: result.size + 'px',
      }
      await nextTick()
    }

    if (result?.meet) {
      if(props.rows == 1) {
        style.value = {
          ...style.value,
          whiteSpace: 'nowrap',
        }
      }
      return
    }
    calcEllipsised()
    if(props.rows == 1) {
      style.value = {
        ...style.value,
        whiteSpace: 'nowrap',
      }
    }
  },
  wait: 100,
  options: {
    leading: true
  }
})

let observer
const handleMutationObserver = () => {
  const config = {
    attributes: false,
    childList: true,
    subtree: true,
  }
  observer = new MutationObserver(() => {
    handler()
  })
  observer.observe(root.value, config)
}

onMounted(() => {
  if (props.observerDOM) {
    handleMutationObserver()
  }
  handler()
})
onActivated(() => {
  if (needRecalculate) {
    if (props.observerDOM) {
      handleMutationObserver()
    }
    handler()
  }
})
onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})
onDeactivated(() => {
  if (observer) {
    observer.disconnect()
  }
})
watch(() => [props.content, props.rows, props.position, props.minSize, props.step, props.maxHeight], () => {
  handler()
})

defineExpose({
  handler
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.cart-common-text-ellipsis {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  &__start,
  &__end {
    display: inline-block;
  }
}
</style>
