/* eslint-disable no-console */
import { createApp } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { debounce } from '@shein/common-function'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'

export class MonitorUserInteraction {
  constructor({ time, line, showRecommendGuide }) {
    this.time = time * 1000
    this.lineIndex = line - 1
    this.targetCartLine = null
    this.recommendDom = null
    this.interactionTimer = null
    this.intersectionObserver = this.setObserverLine()
    this.intersectionRecObserver = this.setObserverRecommend()
    this.overTime = null
    this.overLine = null
    this.showRecommendGuide = showRecommendGuide
  }

  setInteractionTimer() {
    return setTimeout(() => {
      console.log(`[cart低效状态] Staying in cart for ${this.time} ms.`)
      this.overTime = true
      this.checkRecommendGuide()
    }, this.time)
  }

  setObserverLine() {
    const cartBottom = document.querySelector('.j-cart-bottom')?.clientHeight || 0
    const observerOptions = {
      root: null,
      rootMargin: `0px 0px -${cartBottom}px`,
      threshold: 1
    }
    return new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log(`[cart低效状态] Target in view.`)
        this.overLine = true
        this.checkRecommendGuide()
        this.intersectionObserver?.unobserve(this.targetCartLine)
      }
    }, observerOptions)
  }

  setObserverRecommend() {
    const cartBottom = document.querySelector('.j-cart-bottom')?.clientHeight || 0
    const observerOptions = {
      root: null,
      rootMargin: `0px 0px -${cartBottom}px`,
      threshold: 0
    }
    return new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        console.log(`[cart低效状态] Recommend in view.`)
        this.stop({ reason: 'Recommend in view' })
      }
    }, observerOptions)
  }

  checkRecommendGuide() {
    if (this.overTime && this.overLine) {
      // 满足提示条件
      console.log(`[cart低效状态] Meet all conditions.`)
      this.showRecommendGuide()
      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'cart_recommend',
          if_cart_Recommend: 1,
        }
      })
      this.stop({ reason: 'tips has been triggered' })
    }
  }

  checkTargetCartLine() {
    this.targetCartLine = document.querySelectorAll('.j-valid-cart-item')?.[this.lineIndex]
    if (!this.targetCartLine) {
      this.stop({ reason: 'target cart Line is not found' })
      return
    }
    this.intersectionObserver?.observe(this.targetCartLine)
    this.recommendDom = document.querySelector('#j-cart-container__recommend')
    this.intersectionRecObserver?.observe(this.recommendDom)
  }

  // 开始倒计时、绑定监测器
  start() {
    console.log(`[cart低效状态] Start.`)
    this.interactionTimer = this.setInteractionTimer()
    // 如果目标商品行超过6，需要进行滚动后待所有商品行加载完毕再进行目标dom监测
    if (this.lineIndex >= 5) {
      const scrollTest = debounce({
        func: () => {
          this.checkTargetCartLine()
        },
        wait: 800
      })
      window.addEventListener('scroll', scrollTest, { once: true })
    } else {
      this.checkTargetCartLine()
    }
  }

  // 停止倒计时和监测，结束一切逻辑
  stop({ reason = '' } = {}) {
    const reasonTip = reason ? `Reason: ${reason}.` : ''
    console.log(`[cart低效状态] Stop. ${reasonTip}`)
    this.overLine = false
    this.overTime = false
    clearTimeout(this.interactionTimer)
    this.interactionTimer = null
    this.targetCartLine && this.intersectionObserver?.unobserve(this.targetCartLine)
    this.recommendDom && this.intersectionRecObserver?.unobserve(this.recommendDom)
  }
}

// 滚动至底部推荐
export const scrollToRecommend = (style) => {
  const recommendDom = document.querySelector('#j-cart-container__recommend')
  if (!recommendDom) return
  const headerDom = document.querySelector('.j-cart-header-wrap')
  const recommendTop = recommendDom.getBoundingClientRect()?.top
  const headerHeight = headerDom.getBoundingClientRect()?.height || 0
  window.scrollTo({
    top: recommendTop + window.pageYOffset - headerHeight,
    behavior: 'smooth'
  })
  analysis('1-8-3-75', style)
}

export const analysis = (daId, style) => {
  daEventCenter.triggerNotice({
    daId,
    extraData: {
      style,
    }
  })
}

// 元素是否完全在页面可视区域内
const isElementInViewport = (el) => {
  if (!el) return false
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

// 推荐位是否在视口中
export const checkRecommendInView = () => {
  const recommendDom = document.querySelector('#j-cart-container__recommend')
  const rect = recommendDom.getBoundingClientRect()
  const bottomDom = document.querySelector('.j-cart-footer')
  const bottomHeight = bottomDom.getBoundingClientRect()?.height || 0
  const recommendInView = rect.top < window.innerHeight - bottomHeight
  
  if (recommendInView) {
    console.log(`[cart低效状态] Recommend in view, cancel.`)
  }
  return recommendInView
}

export const insertRecommendGuideB = (store) => {
  window.addEventListener('scroll', scrollEv(store), { once: true })
}

// 滚动停止2s后插入提示
const scrollEv = debounce({
  func: (store) => {
    const recommendInView = checkRecommendInView()
    // 如果已经滚动至推荐位，就不需要做插入操作了
    if (!recommendInView) {
      const cartEle = document.querySelectorAll('.j-valid-cart-item')
      const visibleEle = []
      cartEle.forEach((e, index) => {
        if (isElementInViewport(e)) {
          visibleEle.push(index)
        }
      })
      const cartIndex = visibleEle[Math.floor(visibleEle?.length / 2)]
      // 如果插入位置为最后一个有效商品行，则不插入
      if (cartIndex < store.state.cart_v2?.carts?.length - store.state.cart_v2?.soldoutIds?.length - 1) {
        const targetCart = cartEle[cartIndex]
        insertDom(targetCart, store)
      } else {
        console.log(`[cart低效状态] Insertion position at the last line, cancel.`)
      }
    }
  },
  wait: 500
})

const insertDom = (targetCart, store) => {
  const newDiv = document.createElement('div')
  targetCart.parentNode.insertBefore(newDiv, targetCart.nextSibling)
  if (newDiv) {
    import('./RecommendGuideB.vue').then((components) => {
      const app = createApp(components.default)
      app.use(store)
      app.mount(newDiv)
    })
  }
}
