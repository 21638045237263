<template>
  <div class="cart-nav__title">
    <span class="cart-nav__title-name">{{ title }}</span>
    <span
      v-if="cartSum > 0"
      class="cart-nav__title-sum"
    >({{ cartSum }})</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const { state } = useStore()
const language = computed(() => state.language)
const title = computed(() => language.value.SHEIN_KEY_PWA_14965)
const batchActive = computed(() => state.batchActive)
const cartSum = computed(() => batchActive.value ? state.cartInfo.allProductLineSumQuantity : state.cartInfo.effectiveProductLineSumQuantity)
</script>

<style lang="less">
.cart-nav {
  &__title {
    display: flex;
    align-items: center;
    line-height: 19px;
    color: var(--nav-bar-color, #222);
  }
  &__title-name {
    font-size: 16px;
    font-weight: bold;
  }
  &__title-sum {
    font-size: 10px;
    font-weight: 590;
  }
}
</style>
