<template>
  <div
    :class="[
      'buy-get-coupon',
      `buy-get-coupon_${type}`,
      `buy-get-coupon_${couponType}`
    ]"
    :style="styles"
  >
    <template v-if="['instantDiscount', 'discount', 'instantFreeShipping', 'allAmount'].includes(couponType)">
      <div class="buy-get-coupon__value">
        <SAdapterText
          :text="benefitInfo.bgImageText"
          :min-size="minFontSize.value"
        />
      </div>
      <div class="buy-get-coupon__label">
        <SAdapterText
          :text="benefitInfo.bgImageSuffixText"
          :min-size="minFontSize.label"
        />
      </div>
    </template>
    <template v-if="couponType === 'freeShipping'">
      <div class="buy-get-coupon__label">
        <div class="buy-get-coupon__label-text">
          {{ benefitInfo.bgImageSuffixText }}
        </div>
      </div>
    </template>
    <template v-if="couponType === 'mixDiscount'">
      <div class="buy-get-coupon__label">
        <SAdapterText
          :text="benefitInfo.bgImageSuffixText"
          :min-size="minFontSize.mixDiscountLabel"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'

const props = defineProps({
  benefitInfo: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: '1' // 0: 空车 1: 非空车
  }
})

const couponType = computed(() => props.benefitInfo?.couponType || '')
const minFontSize = computed(() => {
  if (props.type === '0') return {
    value: 14,
    label: 10,
    mixDiscountLabel: 13
  }
  return {
    value: 10,
    label: 6,
    mixDiscountLabel: 10
  }
})
const styles = computed(() => {
  return {
    '--bg-image': `url(${props.type == '0' ? props.benefitInfo?.bgImageWithShadow || '' : props.benefitInfo?.bgImage || ''})`
  }
})
</script>

<style lang="less" scoped>
.buy-get-coupon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 5/37.5rem 6/37.5rem;
  width: 57/37.5rem;
  height: 32/37.5rem;
  background-image: var(--bg-image);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.buy-get-coupon_instantDiscount,
.buy-get-coupon_discount,
.buy-get-coupon_instantFreeShipping,
.buy-get-coupon_allAmount {
  .buy-get-coupon__value {
    width: 100%;
    font-size: 12px;
    color: #3A2614;
    font-weight: 1000;
    line-height: 14px;
    text-align: center;
  }
  .buy-get-coupon__label {
    width: 100%;
    font-size: 8px;
    color: #4E3C2A;
    line-height: normal;
    text-align: center;
  }
}

.buy-get-coupon_instantFreeShipping {
  .buy-get-coupon__value {
    color: #169E00;
  }
  .buy-get-coupon__label {
    color: #169E00;
  }
}

.buy-get-coupon_freeShipping {
  .buy-get-coupon__label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 8px;
    text-align: center;
    color: #169E00;
    line-height: normal;
  }
  .buy-get-coupon__label-text {
    width: 100%;
    display: -webkit-box;              
    -webkit-box-orient: vertical;     
    -webkit-line-clamp: 2;            
    overflow: hidden;                  
    text-overflow: ellipsis;
    font-weight: bold; 
  }
}

.buy-get-coupon_mixDiscount {
  .buy-get-coupon__label {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: #3A2614;
    line-height: normal;
    font-weight: bold;
    text-align: center;
  }
}

.buy-get-coupon_0 {
  justify-content: center;
  padding: 9/37.5rem 12/37.5rem 15/37.5rem;
  width: 104/37.5rem;
  height: 60/37.5rem;
  &.buy-get-coupon_instantDiscount,
  &.buy-get-coupon_discount,
  &.buy-get-coupon_instantFreeShipping,
  &.buy-get-coupon_allAmount {
    .buy-get-coupon__value {
      font-size: 20px;
      line-height: 22px;
    }
    .buy-get-coupon__label {
      font-size: 14px;
    }
  }
  &.buy-get-coupon_freeShipping {
    .buy-get-coupon__label {
      font-size: 14px;
      font-weight: bold;
    }
  }
  &.buy-get-coupon_mixDiscount {
    .buy-get-coupon__label {
      font-size: 17px;
    }
  }
}
</style>
