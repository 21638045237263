<template>
  <div
    v-if="lureTipItems.length > 0"
    class="cart-priceLure"
  >
    <div class="cart-priceLure__content">
      <div
        class="cart-priceLure__scroller"
        :style="{'animation': animationStyle, 'animation-play-state': animationStyle ? stopBehaviorAnimation ? 'paused' : 'running' : ''}"
      >
        <div
          v-for="(item, index) in finallyLureTipItems"
          :key="index"
          class="cart-priceLure__item"
        >
          <img
            class="cart-priceLure__item-icon"
            :src="item.icon"
          />
          <span class="cart-priceLure__item-left">{{ item.description }}</span>
          <span
            v-if="item.endTimeStamp || item.endTimeDesc"
            class="cart-priceLure__item-right"
          >
            <span class="cart-priceLure__item-line">|</span>
            <span class="cart-priceLure__item-time">
              <ClientOnly v-if="item.endTimeStamp">
                <CountDown
                  font-size="10px"
                  symbol=":"
                  :timeStamp="item.endTimeStamp"
                />
              </ClientOnly>
              <template v-else>
                {{ item.endTimeDesc }}
              </template>
            </span>
          </span>   
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ClientOnly } from '@sheinfe/vue-client-only'
import { computed, ref, onMounted, watch, nextTick } from 'vue'
import { useStore } from '../../hooks/useStore'
import { buildKeyFramesCSS } from 'public/src/pages/cart_v2/utils/buildKeyFramesCSS.js'
import CountDown from 'public/src/pages/cart_v2/components/common/countDown.vue'

const { getters } = useStore()
const stopBehaviorAnimation = ref(false)
const buildAnimationStyle = ref('')
const lureTipItems = computed(() => getters.lureTipItems)
const animationStyle = computed(() => lureTipItems.value.length > 1 ? buildAnimationStyle.value : '')
const finallyLureTipItems = computed(() => lureTipItems.value.length > 1 ? [...lureTipItems.value, lureTipItems.value?.[0]] : lureTipItems.value)

const loadAnimation = () => {
  if (buildAnimationStyle.value) {
    buildAnimationStyle.value = ''
  }
  if(lureTipItems.value.length < 2) {
    buildAnimationStyle.value = ''
    return
  }
  buildKeyFramesCSS(false, (finallyLureTipItems.value.length - 1), .36, 1.3, {
    name: 'cart-price-lure',
  }).then(animation => {
    buildAnimationStyle.value = animation
  })
}

watch(() => lureTipItems.value.length, (n, o) => {
  if (n > 0) {
    if(n !== o){
      loadAnimation()
    }
  } else {
    loadAnimation()
  }
})
onMounted(() => {
  nextTick(loadAnimation)
})
</script>

<style lang="less">
.cart-priceLure {
  &__content {
    height: 13/37.5rem;
    line-height: 13/37.5rem;
    font-size: 11px;
    overflow: hidden;
  }
  &__scroller {
  }
  &__item {
    display: flex;
    align-items: flex-end;
    color: #FA6338;
    white-space: nowrap;
    line-height: 13/37.5rem;
    height: 13/37.5rem;
  }
  &__item-icon {
    width: 12px;
    height: 12px;
    object-fit: cover;
    flex-shrink: 0;
  }
  &__item-left {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 2/37.5rem;
  }
  &__item-right {
    display: flex;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    .cont-down {
      font-size: 11px;
    }
  }
  &__item-line {
    padding-left: 4/37.5rem;
    padding-right: 4/37.5rem;
    opacity: 0.6;
  }
  &__item-time {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    > .ltr-row {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
