
<template>
  <div
    ref="shrinkTextContainer"
    class="shrink-text"
    :style="style"
  >
    <span>{{ expanded ? content : text }}</span>
    <span
      v-if="hasAction && actionText"
      class="action"
      @click="handleAction"
    >
      {{ actionText }}
    </span>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'ShrinkText',
  props: {
    content: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number, // in px, will calc to rem :(maxWidth * 2 / 75)rem
      required: true,
    },
    position: { // dots position, start, middle,  end
      type: String,
      default: 'end',
    },
    options: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      text: '',
      expanded: false,
      hasAction: false,
      fontSize: '',
    }
  },
  computed: {
    actionText() {
      return this.expanded ? this.config.collapseButtonText : this.config.expandButtonText
    },
    style() {
      let style = {}
      if(this.config.fontSize) style.fontSize = `${this.fontSize}px`
      if(this.config.lineHeight) style.lineHeight = `${this.fontSize * this.config.lineHeight}px`
      if(this.maxWidth) style.width = `${this.maxWidth * 2 / 75}rem`
      return style
    },
    config() {
      return {
        fontSize: 16, // Default font size, in px
        lineHeight: 1.5, // fontSize multiple
        minFontSize: 12, // shrink to this font size at min value in px
        step: 2, // shrink step in px
        // text overflow show dots config
        dots: '...', // ellipsis text symbol
        rows: 1, // max rows
        expandButtonText: '', // expand button text
        collapseButtonText: '', // collapse button text
        ...this.options, // props options to cover default config
      }
    }
  },
  watch: {
    'content': {
      handler() {
        this.setText()
      },
    }
  },
  mounted() {
    if(this.config.fontSize) this.fontSize = this.config.fontSize
    nextTick(() => {
      this.setText()
    })
  },
  methods: {
    px2Num(value) {
      if(!value) return 0
      const match = value.match(/^\d*(\.\d*)?/)
      return match ? Number(match[0]) : 0
    },
    calcEllipsised(containerRef, config) {
      let result = {}
      let fontSize = config.fontSize
      const cloneContainer = () => {
        if (!containerRef) return

        const originStyle = window.getComputedStyle(containerRef)
        const container = document.createElement('div')
        const styleNames = Array.prototype.slice.apply(originStyle)
        styleNames.forEach((name) => {
          container.style.setProperty(name, originStyle.getPropertyValue(name))
        })

        container.style.position = 'fixed'
        container.style.zIndex = '-9999'
        container.style.top = '0'
        container.style.bottom = 'unset'
        container.style.right = 'unset'
        container.style.left = 'unset'
        container.style.height = 'auto'
        container.style.minHeight = 'auto'
        container.style.maxHeight = 'auto'

        container.innerText = config.content
        document.body.appendChild(container)
        return container
      }

      const calcEllipsisText = (
        container,
      ) => {
        const { content, position, dots, actionText } = config
        const end = content.length

        const calcEllipse = () => {
          // calculate the former or later content
          // find a tail that fits the maxHeight
          const tail = (left, right) => {
            let maxHeight = getMaxHeight()
            if(container.offsetHeight > maxHeight && fontSize && fontSize > config?.minFontSize) { // text overflow, then try to shrink font size
              fontSize -= config.step
              container.style.fontSize = `${fontSize}px`
              container.style.lineHeight = `${fontSize * config.lineHeight}px`
              return tail(left, right)
            } else if(container.offsetHeight <= maxHeight && fontSize && content == container.innerText) { // text not ellipsised, and not overflow, then return content
              return content
            }
            // if font shrink to min and text overflow, then try to find a tail that fits the maxHeight, output ellipsised text
            if (right - left <= 1) {
              if (position === 'end') return content.slice(0, left) + dots
              return dots + content.slice(right, end)
            }
            const middle = Math.round((left + right) / 2)

            // Set the interception location
            if (position === 'end') container.innerText = content.slice(0, middle) + dots + actionText
            else container.innerText = dots + content.slice(middle, end) + actionText
            
            // The height after interception still does not match the required height
            if (container.offsetHeight > maxHeight) {
              if (position === 'end') return tail(left, middle)
              return tail(middle, right)
            }
            // low than maxHeight then intercept the latter part try to fit the height
            if (position === 'end') return tail(middle, right)
            return tail(left, middle)
          }
          container.innerText = tail(0, end)
        }

        const middleTail = (
          leftPart,
          rightPart,
        ) => {
          let maxHeight = getMaxHeight()
          if(container.offsetHeight > maxHeight && fontSize && fontSize > config?.minFontSize) { // text overflow, then try to shrink font size
            fontSize -= config.step
            container.style.fontSize = `${fontSize}px`
            container.style.lineHeight = `${fontSize * config.lineHeight}px`
            return middleTail(leftPart, rightPart)
          } else if(container.offsetHeight <= maxHeight && fontSize && content == container.innerText) { // text not ellipsised, and not overflow, then return content
            return content
          }
          if (
            leftPart[1] - leftPart[0] <= 1 &&
            rightPart[1] - rightPart[0] <= 1
          ) {
            return (
              content.slice(0, leftPart[0]) +
              dots +
              content.slice(rightPart[1], end)
            )
          }

          const leftMiddle = Math.floor((leftPart[0] + leftPart[1]) / 2)
          const rightMiddle = Math.ceil((rightPart[0] + rightPart[1]) / 2)

          container.innerText =
            content.slice(0, leftMiddle) +
            dots +
            content.slice(rightMiddle, end) +
            config?.expandButtonText

          if (container.offsetHeight >= maxHeight) {
            return middleTail(
              [leftPart[0], leftMiddle],
              [rightMiddle, rightPart[1]],
            )
          }

          return middleTail(
            [leftMiddle, leftPart[1]],
            [rightPart[0], rightMiddle],
          )
        }

        const middle = (0 + end) >> 1
        position === 'middle'
          ? (container.innerText = middleTail([0, middle], [middle, end]))
          : calcEllipse()
        return container.innerText
      }

      // Calculate the interceptional text
      const container = cloneContainer()
      if (!container) return
      const getMaxHeight = () => {
        const { paddingBottom, paddingTop } = container.style
        return Math.ceil(
          (Number(config.rows) + 0.5) * config.lineHeight * fontSize +
            this.px2Num(paddingTop) +
            this.px2Num(paddingBottom),
        )
      }
      let maxHeight = getMaxHeight()

      if (maxHeight < container.offsetHeight) {
        result = {
          text: calcEllipsisText(container),
          hasAction: true,
          fontSize,
        }
      } else {
        result = {
          text: config.content,
          hasAction: false,
          fontSize,
        }
      }

      document.body.removeChild(container)
      return result
    },
    setText() {
      const result = this.calcEllipsised(
        this.$refs['shrinkTextContainer'],
        {
          content: this.content,
          position: this.position,
          actionText: this.actionText,
          ...this.config,
        }
      )
      if(result?.hasAction) this.hasAction = result?.hasAction
      if(result?.text) this.text = result.text
      if(result?.fontSize) this.fontSize = result?.fontSize
    },
    handleAction(e) {
      this.expanded = !this.expanded
      this.$emit('clickAction', e)
    }
  }
})
</script>

<style lang="less" scoped>
.shrink-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  &__action {
    cursor: pointer;
    &:active {
      opacity: 0.6;
    }
  }
}
</style>
