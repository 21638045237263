<template>
  <div
    class="cart-nav__operation"
  >
    <template v-if="!batchActive">
      <CartNavDot />
      <CartNavBack />
    </template>
    <template v-else>
      <CartNavDone />
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CartNavDot from 'public/src/pages/cart_v2/components/cartHeader/CartNavDot.vue'
import CartNavBack from 'public/src/pages/cart_v2/components/cartHeader/CartNavBack.vue'
import CartNavDone from 'public/src/pages/cart_v2/components/cartHeader/CartNavDone.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const { state } = useStore()
const batchActive = computed(() => state.batchActive)
</script>


<style lang="less">
.cart-nav {
  &__operation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--nav-bar-color, #222);

    .cart-nav__back {
      margin-left: 12/37.5rem;
    }
  }
}
</style>
