<template>
  <div class="cart-CheckoutIncentivePopover checkout-incentive-popover">
    <s-popover
      v-model="showCheckoutBtnPopover"
      trigger="user"
      placemen="top-end"
      :show-close-icon="true"
      :popup-style="popupStyle"
      @icon-close="handleCloseIconClick"
    >
      <IncentivePopoverContent 
        :show="showCheckoutBtnPopover" 
        :module-name="moduleName"
      />
      <template #reference>
        <div
          :class="[
            'checkout-btn-contain',
          ]"
        >
          <slot></slot>
        </div>
      </template>
    </s-popover>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckoutIncentivePopover',
})
</script>

<script setup>
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { computed, watch, onUnmounted } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useStore } from '../../hooks/useStore'
import IncentivePopoverContent from '../cartCheckoutIncentive/IncentivePopoverContent.vue'
import checkoutIncentive from '../cartCheckoutIncentive/checkoutIncentive.js'

const props = defineProps({
  showFilterLabelPopover: {
    type: String,
    default: ''
  }
})

const { state, commit } = useStore()

const content = computed(() => state.checkoutState.currentCartLure || {})
const moduleName = computed(() => state.moduleName)

const emit = defineEmits(['set-popover-type'])

const popupStyle = computed(() => {
  return {
    position: 'fixed',
    top: 'unset',
    bottom: '64px',
  }
})

const showCheckoutBtnPopover = computed({
  get() {
    return Boolean(props.showFilterLabelPopover)
  },
  set(val) {
    if(!val) emit('set-popover-type', '')
  }
})

watch(() => showCheckoutBtnPopover.value, (val) => {
  commit('updateCheckoutState', {
    showCheckoutBtnPopover: !!val,
  })
}, { immediate: true })

onUnmounted(() => {
  commit('updateCheckoutState', {
    showCheckoutBtnPopover: false,
  })
})

const handleCloseIconClick = () => {
  checkoutIncentive.triggerHide()

  daEventCenter.triggerNotice({
    daId: '1-8-3-61',
    extraData: {
      actual_point: content.value.biData?.name,
      information: content.value.biData?.information || '',
    }
  })
}
</script>
<style lang="less">
.cart-CheckoutIncentivePopover {
  .sui-popover__trigger {
    width: 100%;
  }
}
</style>
