function bindContainerScrollListener () {
  // 优惠券助手悬浮窗
  const animateStart = (configs, duration = 361) => {
    configs.forEach(item => {
      const element = document.querySelector(item.attr)
      if (element) {
        const keyframes = []
        if (item.needOpacity) keyframes.push({ opacity: +(window.getComputedStyle(element).opacity) })
        keyframes.push(item.style)
      
        element.animate(
          keyframes,
          { duration: duration, fill: 'forwards', easing: 'linear' }
        )
      }
    })
  }

  let lastTimeScrollTop = 0
  const setCouponHelperBehavior = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if ((lastTimeScrollTop && scrollTop) || (lastTimeScrollTop <= 0 && scrollTop <= 0)) return lastTimeScrollTop = scrollTop
    lastTimeScrollTop = scrollTop
    if (scrollTop == 0) {
      animateStart([
        { attr: '.wrapper-right', style: { opacity: 1 }, needOpacity: true },
        { attr: '.circle-progress', style: { opacity: 0 }, needOpacity: true },
        { attr: '#coupon-helper-wrapper', style: { width: '100%', height: '44px' } },
        { attr: '.wrapper-left', style: { width: '38px', height: '38px' } },
      ])
      // document.querySelector('.circle-progress')?.__vue__ 这样可以获取进度条的组件
    } else {
      animateStart([
        { attr: '.wrapper-right', style: { opacity: 0 }, needOpacity: true },
        { attr: '.circle-progress', style: { opacity: 1 }, needOpacity: true },
        { attr: '#coupon-helper-wrapper', style: { width: '34px', height: '34px' } },
        { attr: '.wrapper-left', style: { width: '30px', height: '30px' } },
      ])
    }
  }
  let upttDom = null
  const handleScroll = () => {
    if (!this.$router) return 
    const routerName = this.$route.name
    const switching = this.$router.switching

    // 路由切换时 or 路由非cart时 return
    if(switching || routerName !== 'cart') {
      upttDom = null
      return
    }

    if(this.carts?.length){
      // fullGiftSticky()
      setCouponHelperBehavior()
    }
    if(!upttDom) {
      upttDom = document.querySelector('.j-uptt')
    }
    if(!upttDom) return
    if(!(upttDom.classList.contains('page-cart-top'))){
      const bottomH = document.querySelector('.j-cart-footer')?.clientHeight || 0
      upttDom.classList.add('page-cart-top') // 解决一键回到顶部按钮层级被覆盖的问题
      upttDom.style.bottom = `calc(${bottomH ? `${bottomH + 20}px` : '3rem'} - env(safe-area-inset-bottom))`
    }
  }
  window.onscroll = handleScroll
}

export {
  bindContainerScrollListener
}
