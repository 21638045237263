<template>
  <div
    class="cart-nav j-cart-header"
    :style="{
      '--nav-bar-bg': `${titleNavStyleFromCcc?.bgImage?.src ? `url('${titleNavStyleFromCcc?.bgImage?.src}')` : ''}`,
      '--nav-bar-color': titleNavStyleFromCcc?.fontColor || '',
      '--nav-bar-font-opacity': '0.6',
    }"
  >
    <STitleNav
      class="j-cart-nav cart-nav_ccc"
    >
      <template #prepend>
        <CartNavAll />
      </template>
      <template #default>
        <CartNavMid />
      </template>
      <template #suffix>
        <CartNavOperation />
      </template>
    </STitleNav>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { STitleNav } from '@shein-aidc/sui-title-nav/mobile'
import CartNavAll from 'public/src/pages/cart_v2/components/cartHeader/CartNavAll.vue'
import CartNavMid from 'public/src/pages/cart_v2/components/cartHeader/CartNavMid.vue'
import CartNavOperation from 'public/src/pages/cart_v2/components/cartHeader/CartNavOperation.vue'
import { useStore } from '../../hooks/useStore'

const { state } = useStore()
const titleNavStyleFromCcc = computed(() => state.titleNavStyleFromCcc)
</script>

<style lang="less">
.cart-nav {
  .cart-nav__operation {
    margin-left: 24/37.5rem;
  }
  .cart-nav_ccc {
    background: var(--nav-bar-bg, @sui_color_white);
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: var(--nav-bar-color, @sui_color_gray_dark1);
  }
}
</style>
