<template>
  <component
    :is="componentName"
    :shipping-info="shippingInfo"
    :language="language"
    :show-cart-rights="showCartRights"
    :css-right="locals && locals.GB_cssRight"
    :mall-code="getters['singleMallCode']"
    @addOn="handleAddOn"
  />
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartTopFreeShippingActivity',
})
</script>
<script setup>
import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import NewFreeShip from './NewFreeShip.vue'
import FreeShip from './FreeShip.vue'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
const AddItem = useCartAddItem()

const { state, getters } = useStore()

const showNewFreeShipping = computed(() => getters['isShowNewFreeShip'])
const shippingInfo = computed(() => state.mallShippingInfo[`${getters['singleMallCode']}`] || null)
const language = computed(() => state.language)
const locals = computed(() => state.locals)

const componentName = computed(() => {
  return showNewFreeShipping.value ? NewFreeShip : FreeShip
})
const showCartRights = computed(() => getters['showCartRights'])

const handleAddOn = (payload) => {
  const options = payload?.type === 'coupon' ? {
    coupon: payload?.coupon,
    state: 'coupon_helper_add',
  } : {
    popupInfoId: payload?.popupInfoId,
    state: 'shipping_add',
  }
  AddItem.show(options)
}

</script>
