<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect
      width="16"
      height="16"
      fill="white"
      fill-opacity="0.01"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6056 4.61161V2.66602H2.00684V5.76272V9.52826V12.3004H3.17434C3.49373 11.1831 4.52255 10.3652 5.74239 10.3652C6.96223 10.3652 7.99106 11.1831 8.31045 12.3004H9.07814C9.39753 11.1831 10.4264 10.3652 11.6462 10.3652C12.8657 10.3652 13.8943 11.1826 14.214 12.2994H15.0467V5.73658L11.6056 4.61161ZM11.6056 8.29856V6.01371L13.713 6.70256V8.29856H11.6056ZM13.0114 13.0345C13.0114 13.7709 12.4144 14.3678 11.6781 14.3678C10.9417 14.3678 10.3447 13.7709 10.3447 13.0345C10.3447 12.2981 10.9417 11.7012 11.6781 11.7012C12.4144 11.7012 13.0114 12.2981 13.0114 13.0345ZM5.74251 14.3678C6.47889 14.3678 7.07585 13.7709 7.07585 13.0345C7.07585 12.2981 6.47889 11.7012 5.74251 11.7012C5.00613 11.7012 4.40918 12.2981 4.40918 13.0345C4.40918 13.7709 5.00613 14.3678 5.74251 14.3678Z"
      fill="#0E8C33"
    />
    <mask
      id="mask0_492_21089"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="14"
      height="11"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6056 4.61161V2.66602H2.00684V5.76272V9.52826V12.3004H3.17434C3.49373 11.1831 4.52255 10.3652 5.74239 10.3652C6.96223 10.3652 7.99106 11.1831 8.31045 12.3004H9.07814C9.39753 11.1831 10.4264 10.3652 11.6462 10.3652C12.8657 10.3652 13.8943 11.1826 14.214 12.2994H15.0467V5.73658L11.6056 4.61161ZM11.6056 8.29856V6.01371L13.713 6.70256V8.29856H11.6056Z"
        fill="#4B0303"
      />
    </mask>
    <g mask="url(#mask0_492_21089)">
      <circle
        cx="0.450391"
        cy="0.14961"
        r="8.55"
        fill="white"
        fill-opacity="0.2"
      />
    </g>
  </svg>
</template>
