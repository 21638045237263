<template>
  <component
    :is="componentName"
    :cart-rights="cartRights"
  />
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartTopRights',
})
</script>
<script setup>
import { computed, defineProps } from 'vue'
import NewUser from './NewUser.vue'
import OldUserA from './OldUserA.vue'
import OldUserB from './OldUserB.vue'

const props = defineProps({
  cartRights: {
    type: Object,
    default: () => ({})
  }
})

const componentName = computed(() => {
  if (props.cartRights?.isNewUser) return NewUser
  if (props.cartRights?.oldUserType == '1') return OldUserA
  if (props.cartRights?.oldUserType == '2') return OldUserB
  return null
})
</script>
