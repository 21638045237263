<template>
  <div
    v-tap="{ id: '1-8-7-8' }"
    class="cart-nav__operation-done"
    @click="handleClick"
  >
    {{ language.SHEIN_KEY_PWA_15042 }}
  </div>
</template>

<script setup>
import { vTap } from 'public/src/pages/common/analysis/directive'
import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const { state, commit } = useStore()
const language = computed(() => state.language)

const handleClick = () => {
  setTimeout(() => {
    document.querySelector('.j-cart-list-filter')?.scrollTo({ left: 0 })
  }, 0)
  commit('handleBatchActive', { checkSoldout: true })
}
</script>

<style lang="less">
.cart-nav {
  &__operation-done {
    font-size: 14px;
    line-height: 1;
    color: var(--nav-bar-color, #222);
  }
}
</style>
