<template>
  <div>
    <s-alert
      v-expose="{ id: '1-8-1-10' }"
      class="cart-top-notice"
      type="info"
      :closeable="true"
      :show-icon="true"
      :load-more-txt="language['SHEIN_KEY_PWA_15698']"
      :description="message"
      @close="close"
      @onLoadMoreClick="onLoadMoreClick"
    />
    <s-dialog
      v-model:visible="isShowNotic"
      :show-close="false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div v-html="message">
      </div>
      <template #footer>
        <s-button
          :type="['primary']"
          :width="'100%'"
          @click="noticClosehandle"
        >
          {{ language['SHEIN_KEY_PWA_15312'] }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>
<script> 
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartTopNotice',
})
</script>
<script setup>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { ref, defineProps } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'

defineProps({
  message: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

let isShowNotic = ref(false)

const onLoadMoreClick = () => {
  isShowNotic.value = true
  daEventCenter.triggerNotice({ daId: '1-8-1-12' })
}

const close = () => {
  daEventCenter.triggerNotice({ daId: '1-8-1-11' })
}

const noticClosehandle = () => {
  isShowNotic.value = false
}
</script>
<style lang="less">
.cart-top-notice{
  padding-top: 20/75rem;
  padding-bottom: 20/75rem;
  max-height: 1.92rem;
  overflow: hidden;
}
</style>
