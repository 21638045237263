<template>
  <div class="benefit-info">
    <component
      :is="Component"
      :benefit-info="benefitInfo"
      :language="language"
      :type="type"
    />
    <div
      :class="[
        'benefit-info__content',
        `benefit-info__content_${type}`
      ]"
    >
      <div class="benefit-info__title">
        {{ unpaidOrderInfo.waitingPaymentTip }}
      </div>
      <div class="benefit-info__bottom">
        <CountDown
          v-if="showCountDown"
          :timeStamp="unpaidOrderInfo.expireCountdown"
          :styleConfig="{
            '--font-color': '#fff',
            '--num-bg-color': '#FA6338',
            '--divider-color': '#FA6338',
            '--divider-font-weight': 'bold'
          }"
        />
        <span
          v-if="showCountDown && savePriceAmountWithSymbol"
          class="benefit-info__divider"
        ></span>
        <div
          v-if="savePriceAmountWithSymbol"
          class="benefit-info__saved"
        >
          <span class="benefit-info__saved-label">{{ language.SHEIN_KEY_PWA_30913 }}:</span>
          <span class="benefit-info__saved-value">{{ savePriceAmountWithSymbol }}</span>
        </div>
      </div>
    </div>
    <div
      class="benefit-info__button"
      @click.stop="onClickView"
    >
      {{ language.SHEIN_KEY_PWA_17171 }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import NewUserGifts from './NewUserGifts.vue'
import BuyGetCoupon from './BuyGetCoupon.vue'
import CreditReward from './CreditReward.vue'
import CountDown from 'public/src/pages/cart_v2/components/cartPromotionCouponMix/components/CountDown.vue'

const COMPONENT_MAP = {
  NewUserGifts,
  BuyGetCoupon,
  CreditReward
}

const props = defineProps({
  unpaidOrderInfo: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => ({})
  },
  type: {
    type: String,
    default: '1' // 0: 空车 1: 非空车
  }
})
const emit = defineEmits(['viewOrder'])
const benefitInfo = computed(() => props.unpaidOrderInfo.benefitInfo || {})
const benefitType = computed(() => benefitInfo.value?.type || '')
const Component = computed(() => COMPONENT_MAP[benefitType.value])
const savePriceAmountWithSymbol = computed(() => {
  if (props.type === '0') return ''
  if (props.type === '1') {
    return !['BuyGetCoupon', 'CreditReward'].includes(benefitType.value) && props.unpaidOrderInfo?.savedPrice?.amount > 0 ? props.unpaidOrderInfo?.savedPrice?.amountWithSymbol : ''
  }
  return props.unpaidOrderInfo?.savedPrice?.amount > 0 ? props.unpaidOrderInfo?.savedPrice?.amountWithSymbol : ''
})
const showCountDown = computed(() => {
  return props.unpaidOrderInfo?.expireCountdown >= (new Date().getTime()) / 1000
})

const onClickView = () => {
  emit('viewOrder')
}
</script>

<style lang="less" scoped>
.benefit-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  width: 100%;
  .benefit-info__content {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    // height: 49/37.5rem;
    min-width: 0;
    margin: 0 10/37.5rem;
    .benefit-info__title {
      width: 100%;
      font-size: 12px;
      color: #000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: normal;
    }
    .benefit-info__bottom {
      display: inline-flex;
      align-items: center;
      margin-top: 2/37.5rem;
      line-height: normal;
    }
    :deep(.cart-count-down) {
      display: inline-flex;
      align-items: center;
      line-height: normal;
    }
    .benefit-info__divider {
      flex-shrink: 0;
      margin: 0 6/37.5rem;
      width: 1/37.5rem;
      height: 10/37.5rem;
      background: rgba(34, 34, 34, 0.30);
    }
    .benefit-info__saved {
      display: inline-flex;
      min-width: 0;
      font-size: 12px;
      color: #FA6338;
    }
    .benefit-info__saved-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .benefit-info__saved-value {
      margin-left: 2/37.5rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .benefit-info__content_0 {
    justify-content: flex-start;
    .benefit-info__title {
      font-size: 13px;
      color: #161616;
      font-weight: bold;
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 定义显示的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .benefit-info__bottom {
      margin-top: 6/37.5rem;
    }
  }
  .benefit-info__button {
    padding: 5/37.5rem 8/37.5rem;
    max-width: 66/37.5rem;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    background: #FA6338;
    border-radius: 28/37.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
