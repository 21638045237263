<template>
  <div class="cart-nav__mid">
    <CartNavTitle />
    <div
      v-show="!batchActive"
      class="cart-nav__address"
    >
      <CartAddress
        :styleConfig="styleConfig"
        :showLocationIcon="false"
        :showMoreIcon="true"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CartNavTitle from 'public/src/pages/cart_v2/components/cartHeader/CartNavTitle.vue'
import CartAddress from 'public/src/pages/cart_v2/components/cartAddress/index.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const { state } = useStore()
const batchActive = computed(() => state.batchActive)

const styleConfig = computed(() => {
  return {
    backgroundColor: 'transparent',
    padding: '0',
    borderRadius: '0',
    fontSize: '10px',
    lineHeight: '1',
  }
})
</script>

<style lang="less">
.cart-nav {
  &__mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  &__address {
    position: relative;
    z-index: 10;
    width: 100%;
    text-align: center;
    .text-overflow();
    min-height: 12px;
    &:empty {
      display: none;
      height: 0;
    }
  }
}
</style>
