import { ref, onMounted } from 'vue'
import { useStore } from '../../../hooks/useStore.js'
import bottomPromoGuide from './bottomPromoGuide.js'
import { getCache, setCache } from '../cache.js'

export const useBottomPromoGuide = () => {
  const CACHE_KEY = 'PromoCouponMixGuide'

  const { getters } = useStore()

  const bottomPromoGuideShow = ref(false)

  const onBottomPromoGuideClose = () => {
    bottomPromoGuideShow.value = false
    bottomPromoGuide.end()
  }

  onMounted(() => {
    bottomPromoGuide.initialize({
      start() {
        bottomPromoGuideShow.value = true
      },
      close() {
        setCache(CACHE_KEY, true)
      },
      isCompleted() {
        if (typeof window === 'undefined') return true
        return !!getCache(CACHE_KEY) || !getters['showNewPromoBar']
      },
    })
  })

  return {
    bottomPromoGuideShow,
    onBottomPromoGuideClose,
  }
}
