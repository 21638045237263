import { computed, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'

export function getRouter() {
  const CartVue = typeof window !== 'undefined' ? window.CartVue : {}
  return {
    router: CartVue?.$router || {},
    route: CartVue?.$route || {},
  }
}

export function useCartFloorDrawer({ anchorToCartItem }) {
  const { registerModalState, updateModalState } = useUpdateModalState()
  registerModalState()
  const { state, commit, getters } = useStore()
  
  const notShowCartFloorDrawer = computed(() => state.notShowCartFloorDrawer)
  const isShowCartFloorDrawer = computed(() => state.isShowCartFloorDrawer)

  const cartfloorInform = computed(() => getters['cartfloorInform'])

  const handleToCartItem = () => {
    const { route, router } = getRouter()
    if (route.query.to !== '0') {
      anchorToCartItem(route.query.to)
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    // 清除购物车楼层跳转参数
    router.replace({
      ...route,
      query: {},
    })
  }

  const initCartFloorDrawer = () => {
    const { route } = getRouter()
    if (route.query.from === 'cart_floor') {
      if (cartfloorInform.value) {
        commit('updateState', {
          key: 'showCartFloorDrawer',
          value: true,
        })
      } else {
        handleToCartItem()
        updateModalState()
      }
    } else if (route.query.from === 'footer_cart') {
      commit('updateState', {
        key: 'showCartFloorDrawer',
        value: true,
      })
    } else {
      updateModalState()
    }
  }

  // 从楼层组件跳到购物车时，如果利诱弹窗只有一个商品，不展示利诱弹窗，滚动到商品位置
  watch(() => notShowCartFloorDrawer.value, (v) => {
    if (v && !isShowCartFloorDrawer.value && cartfloorInform.value) {
      handleToCartItem()
      updateModalState()
    }
  }, {
    immediate: true
  })


  return {
    initCartFloorDrawer,
  }
}
