<template>
  <div>
    <Transition name="vue-slide-layer-bottom-fade">
      <div
        v-show="!batchActive"
        id="bottomPop"
        class="c-cart-checkout j-cart-checkout"
      >
        <!-- BNPL 结算 B 方案 popover 气泡 -->
        <Transition name="bnpl-popover">
          <div
            v-if="payShowStyleB && !hasCloseBNPLPopover && !showDiscountDetails && !isShowCartIncentiveProductList && !isShowCartAssetsBenefitPop"
            class="cart-checkout-bnpl__popover-wrap"
          >
            <CartBNPLPopover
              placement="bottom" 
              @close="hasCloseBNPLPopover = true"
            />
          </div>
        </Transition>

        <!-- 普通版本结算 BAR -->
        <Transition :name="normalBarTransName">
          <CartCheckoutBarNormal
            v-if="(isServer && !showPayBtn) || !isServer"
            v-show="!showPayBtn"
            :handle-discount-details="handleDiscountDetails"
            :show-filter-label-popover="!showPayBtn ? checkoutButtonPopoverType : ''"
            :prefetch-checkout="prefetchCheckout"
            :font-size="fontSize"
            :checked-quantity="cartCheckedNum"
            :control-checkout-name-text="controlCheckoutNameText"
            :show-new-free-shipping="showFreeShippingTag"
            :click-checkout="clickCheckout"
            :language="language"
            :show-quick-ship="showQuickShipTag"
            @set-popover-type="setPopoverTypeWithPriority"
          />
        </Transition>
        <!-- BNPL 版本结算 BAR -->
        <Transition :name="bnplBarTransName">
          <CartCheckoutBarBNPL
            v-if="(isServer && showPayBtn) || !isServer"
            v-show="showPayBtn"
            :handle-discount-details="handleDiscountDetails"
            :show-filter-label-popover="showPayBtn ? checkoutButtonPopoverType : ''"
            :prefetch-checkout="prefetchCheckout"
            :font-size="fontSize"
            :checked-quantity="cartCheckedNum"
            :control-checkout-name-text="controlCheckoutNameText"
            :show-new-free-shipping="showFreeShippingTag"
            :click-checkout="clickCheckout"
            :language="language"
            :show-quick-ship="showQuickShipTag"
            :show="showPayBtn"
            :pay-show-style-a="payShowStyleA"
            @set-popover-type="setPopoverTypeWithPriority"
          />
        </Transition>
      </div>
    </Transition>
    <ClientOnly>
      <!-- paypal回调邮箱后确认信息弹框 -->
      <email-confirm-dialog
        v-if="asyncComponentLoadedStatus.cartEmailConfirmDrawIsLoaded"
        @goCheckoutPageByPaypal="goCheckoutPageByPaypal"
      />
      <!-- 是有paypal回调邮箱进行注册账号弹出 -->
      <account-register-dialog v-if="asyncComponentLoadedStatus.cartAccountRegisterDrawIsLoaded" />
      <!-- 快速注册失败弹框 -->
      <register-failed-dialog
        v-if="asyncComponentLoadedStatus.cartRegisterFailedDrawIsLoaded"
        @goCheckoutPageByPaypal="goCheckoutPageByPaypal"
      />
    </ClientOnly>
    <LowPriceUserIndex
      v-if="asyncComponentLoadedStatus.cartLowPriceUserDrawIsLoaded"
      :show-new-free-shipping="showFreeShippingTag"
      :show-quick-ship="showQuickShipTag"
      @emitClickCheckoutEvt="emitClickCheckoutEvt"
      @goCheckoutEvt="goCheckoutEvt"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartCheckout',
  mounted() {
    window.cartCheckout = this
  },
})
</script>
<script setup>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { computed, ref, onActivated, onMounted, nextTick, watch, onDeactivated, defineExpose, defineAsyncComponent } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useRouter } from '../../hooks/useRouter'
import CartCheckoutBarBNPL from './CartCheckoutBarBNPL.vue'
import CartCheckoutBarNormal from './CartCheckoutBarNormal.vue'
import CartBNPLPopover from './CartBNPLPopover.vue'
import { amountWithSymbolTransform, getBrowserName } from '../../utils/index.js'
import {
  usePopStatus,
  CART_INCENTIVE_PRODUCT_LIST,
  CART_ASSETS_BENEFIT_POP,
} from '../../hooks/usePopLoader'
import PaypalSDK from 'public/src/pages/common/paypal/paypalSdk.js'
import checkoutIncentive from '../cartCheckoutIncentive/checkoutIncentive.js'
import { abtservice } from 'public/src/services/abt'
import prerenderCheckout from '../../utils/prerender'
import { web_checkout_button_click, web_checkout_button_jump } from '../../utils/metricTagsConfig.js'
import { checkStock } from 'public/src/pages/product_app/store/modules/cart_v2/api.js'
import { daEventCenter } from 'public/src/services/eventCenterV2'
import apiReport from 'public/src/pages/common/apiReport'
import { CHECKOUT_REFETCH_CART_API } from 'public/src/pages/cart_v2/utils/PromiseActuator.js'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { BOTTOM_PROMOTION_TRANSFORMATION } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import { useHandleDiscountDetails } from 'public/src/pages/cart_v2/hooks/useHandleDiscountDetails.js'
import { setCache } from '../../utils/fetcher'
import UserInfoManager from 'public/src/services/UserInfoManager/index'

const emailConfirmDialog = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-email-account-register-dialog" */'public/src/pages/cart_v2/components/paypalLoginDialog/emailConfirmDialog.vue'))
const accountRegisterDialog = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-email-account-register-dialog" */'public/src/pages/cart_v2/components/paypalLoginDialog/accountRegisterDialog.vue'))
const registerFailedDialog = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-email-account-register-dialog" */'public/src/pages/cart_v2/components/paypalLoginDialog/registerFailedDialog.vue'))
const LowPriceUserIndex = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-low-price-dialog" */'public/src/pages/cart_v2/components/lowPriceUser/index.vue'))

const { state, getters, commit, dispatch } = useStore()
const { handleDiscountDetails } = useHandleDiscountDetails()
const { routerPush } = useRouter()
const { isActivePopover, activatePopover, deactivatePopover, registerPopover } = usePopoverManager({ type: BOTTOM_PROMOTION_TRANSFORMATION })
registerPopover()
const { isShow: isShowCartIncentiveProductList, data: cartIncentiveProductListData } = usePopStatus(CART_INCENTIVE_PRODUCT_LIST)
const { isShow: isShowCartAssetsBenefitPop } = usePopStatus(CART_ASSETS_BENEFIT_POP)
const isServer = ref(true)
const normalBarTransName = ref('') // 'normal-bar'
const bnplBarTransName = ref('') // 'bnpl-bar'
let hasCloseBNPLPopover = ref(false)
let checkoutButtonPopoverType = ref('')
let fontSize = ref('14px')
let isFirstRenderPaypal = ref(true)
let abtTest = ref({})
let prefetchCheckoutInfo = null
let lowerPriceParams = {}
let { langPath } = gbCommonInfo
let scene = 'cart'

const selectFilterLabel = computed(() => state?.filterState?.selectFilterLabel)
const couponInfo = computed(() => state.couponInfo)
const language = computed(() => state.language)
const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show)
const carts = computed(() => state.carts)
const cartIds = computed(() => state.cartIds)
const abtInfo = computed(() => state.cartAbtInfo)
const batchActive = computed(() => state.batchActive)
const payShowStyleB = computed(() => getters['payShowStyleB'])
const payShowStyleA = computed(() => getters['payShowStyleA'])
const paymentFrontPaymentList = computed(() => getters['paymentFrontPaymentList'])
const showPayBtn = computed(() => showCartPayPalBtn.value || payShowStyleA.value)
const showCartPayPalBtn = computed(() => getters['showPaypalBtn'] && state.checkoutState.renderPaypalBtnStatus > 0)
const cartInfo = computed(() => state.cartInfo)
const mallCartPriceData = computed(() => cartInfo.value?.mallCartPriceData)
const totalPrice = computed(() => mallCartPriceData.value?.totalPrice?.price)
const totalPriceAmountWithSymbol = computed(() => amountWithSymbolTransform(totalPrice.value))
const controlCheckoutNameText = computed(() => {
  const { interceptAction: action, interceptType, checkoutButtonText } = cartInfo.value?.cartInterceptions?.find(i => i.activityType == 1) || {}
  // checkout文案展示调整
  let {
    ControlLowpriceUser = '',
    ControlCheckoutname = '',

  } = abtInfo.value?.LowpriceUser?.param || {}

  // 临时后端兼容性措施, 低客拦截abt
  if(!ControlLowpriceUser || ControlLowpriceUser == 'off') return ''

  if(!action) return ''
  else if((action == 1 || action == 2) && Number(interceptType || 0) > 0 ){
    if(ControlCheckoutname == 'on') return checkoutButtonText || ''
    else return ''
  } else return ''
})

const checkoutLabelList = computed(() => state.checkoutState?.checkoutLabelList)
// 是否展示freeShipping
const showFreeShippingTag = computed(() => {
  return checkoutLabelList.value?.find(item => item.type == 1)?.tip || false
})
// 是否展示quickship
const showQuickShipTag = computed(() => {
  return checkoutLabelList.value?.find(item => item.type == 0)?.tip || false
})
const asyncComponentLoadedStatus = computed(() => state.asyncComponentLoadedStatus)
const channelSessionParams = computed(() => state.paypalState.channelSessionParams)
const loginStatus = computed(() => state.loginStatus)
const cartCheckedNum = computed(() => cartInfo.value.checkedCartSumQuantity)
const openControlLowPriceUser = computed(() => {
  const { interceptType = 0, lowThresholdValue = 0 } = cartInfo.value?.cartInterceptions?.find(i => i.activityType == 1) || {}

  return {
    interceptType,
    lowThresholdValue
  }
})

const mallCartBiData = computed(() => state.mallCartBiData)
const checkoutInfo = computed(() => state.checkoutState.checkoutInfo)

watch(() => totalPriceAmountWithSymbol.value, (val) => {
  handleCheckoutContentLength(val)
})

watch(() => channelSessionParams.value, (n, o) => {
  if (typeof window == 'undefined') return
  if(n) {
    if(!o){
      initPaypalSDK()
    } else {
      if((n.shipCountry != o.shipCountry) || (n.orderCurrency != o.orderCurrency) || (n.orderDetails.length != o.orderDetails.length)) {
        initPaypalSDK()
      } else if(n.orderDetails.length == o.orderDetails.length){
        let diff = false
        for(let i = 0; i < n.orderDetails.length; i++) {
          let targetItem = o.orderDetails.find(item => (n.orderDetails[i].businessModel === item.businessModel && n.orderDetails[i].companyId === item.companyId))
          if(!targetItem) {
            diff = true
            break
          }
        }
        if(diff) {
          initPaypalSDK()
        }
      }
    }
  }
}, { deep: true })

watch(() => showCartPayPalBtn.value, (val) => {
  if((typeof window != 'undefined') && val) {
    commit('changeAsyncPaypalDialogLoadedStatus', {
      show: true,
    })
  }
}, { immediate: true })

onDeactivated(() => {
  hasCloseBNPLPopover.value = false
})

onMounted(() => {
  handleCheckoutContentLength()
  initLowerPriceUserAbtTest()
  // mounted 再设置过度动画，主要是避免 v-if 第一次的出场动画
  normalBarTransName.value = 'normal-bar'
  bnplBarTransName.value = 'bnpl-bar'
  isServer.value = false
})

onActivated(() => {
  if(!isFirstRenderPaypal.value && showCartPayPalBtn.value && state.checkoutState.channelSessionParams) {
    initPaypalSDK()
  }
})


const setPopoverTypeWithPriority = (value) => {
  if(value == '' || checkoutButtonPopoverType.value == '') {
    checkoutButtonPopoverType.value = value
    return true
  }
  const Priority = ['newUser', 'incentive', 'filter']
  let targetOrder = Priority.indexOf(value)
  let currentOrder = Priority.indexOf(checkoutButtonPopoverType.value)
  if(targetOrder >= 0 && targetOrder < currentOrder) {
    checkoutButtonPopoverType.value = value
    return true
  }
  return false
}

onMounted(() => {
  checkoutIncentive.setHooks({
    async show(data) {
      await activatePopover()
      if (state.cartLeave) {
        deactivatePopover()
        return false
      }
      if (isActivePopover) {
        commit('updateCheckoutState', {
          currentCartLure: data.cartLure,
        })
        const res = setPopoverTypeWithPriority('incentive')
        return res
      }
      return false
    },
    async hide() {
      await deactivatePopover()
      if (checkoutButtonPopoverType.value !== 'incentive') {
        return false
      }
      setPopoverTypeWithPriority('')
      return true
    },
  })
})

const handleCheckoutContentLength = () => {
  nextTick(()=>{
    const ed = document.querySelector('.j-totalDiscountAmount .total-discount-txt') || {}
    const e = document.querySelector('.j-totalAmount .price-amount-decimal') || {}
    const withPrice = document.querySelector('.j-installmentPrice') || {}
    const normalPrice = document.querySelector('.j-normalPrice') || {}
    if (
      e.scrollHeight > (e.clientHeight + 1)
          || ed.scrollWidth > ed.clientWidth
          || withPrice.scrollWidth > withPrice.clientWidth
          || normalPrice.scrollWidth > normalPrice.clientWidth
    ) {
      fontSize.value = '12px'
    } else {
      fontSize.value = '14px'
    }
  })
}
const goCheckoutPageByPaypal = async () => {
  // 登录后重新查车
  await dispatch('fetchCartIndex')
  commit('updateState', {
    key: 'loginStatus',
    value: true
  })
  // 跳转到下单页
  clickCheckout({ paymentCode: 'PayPal-GApaypal', foldPayment: true })
}

const initPaypalSDK = () => {
  nextTick(()=>{
    isFirstRenderPaypal.value = false
    const data = {
      ...channelSessionParams.value,
      orderAmount: totalPrice.value?.amount
    }
    // const data = {
    //   shipCountry: 'us',
    //   orderAmount: '100',
    //   orderCurrency: 'USD',
    //   payMethodList: ['PayPal-GApaypal'],
    //   orderDetails: [{ businessModel: 0, companyId: 1 }]
    // }
    // res: paypalSDK返回的email是否已注册账户接口的返回结果
    // email: paypalSDK返回的email账户
    PaypalSDK.getClientToken(data, (res, email) => {
    // 从paypal渠道页返回到shein 购物车页面，paypal正常回传邮箱，接口被拦截（risk_decision=2）
      if(res?.info?.risk_decision == 2) {
      // 弹出失败弹窗，弹窗内点击“更换方式注册”按钮，点击进入登录注册页面，或者点击取消弹窗消失
        commit('updatePaypalState', {
          showRegisterFailedDialog: true
        })
        return
      } else if(res?.info?.risk_decision == 1) {
      // 从paypal返回到shein 购物车页面，paypal正常回传邮箱，接口被放行（risk_decision=1）
      // 当前用户未注册，判断paypalSDK返回的email账户是否已在shein注册
        if(res?.info?.is_existed === 0) {
        // 未注册：弹出确认使用paypalSDK返回的email进行快速注册
          commit('updatePaypalState', {
            emailConfirmDialogInfo: { email },
            showEmailConfirmDialog: true
          })
        } else if(res?.info?.is_existed === 1) {
        // 已注册：跳转至登录页
          SHEIN_LOGIN && SHEIN_LOGIN.show({
            show: true,
            instance: 'paypalCheckout',
            action: 'switch_account',
            bi: 'paypal_checkout',
            from: 'paypal_checkout',
            switchAccountVal: {
              accountType: res?.info?.loginAccountType || 7,
              accountAlias: email
            },
            cb: () => {
            // 登录成功，跳转下单页
              goCheckoutPageByPaypal()
            }
          })
        }
      }
    }, (renderPaypalBtnStatus) => {
      commit('updateCheckoutState', {
        renderPaypalBtnStatus: renderPaypalBtnStatus ? 1 : 0
      })
    }, () => {
      if(!cartCheckedNum.value){
        SToast(state.cartInfo.checkedInterceptTip || language.value.SHEIN_KEY_PWA_20628, 3000)
        return { show: false }
      }
      return { show: true, orderAmount: totalPrice.value?.amount }
    })
  })
}

const initLowerPriceUserAbtTest = () =>{
  let lowPriceUser = abtInfo.value?.LowpriceUser || {}
  // eslint-disable-next-line @shein-aidc/abt/abt
  let abtTest = abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'LowpriceUser': lowPriceUser } })
  abtTest.value = abtTest || {}
}

const prefetchCheckout = (step, paymentCode = '') => {
  if (window.toCheckout) return
  if (step === 1) {
    if(prefetchCheckoutInfo || !cartCheckedNum.value || !loginStatus.value) return
    prefetchCheckoutInfo = prerenderCheckout.getCache(paymentCode)
  }
}


const emitInitCheckout = ({ value = 0, msg = '', scenes = '', reason = '', options = {}, notSendTpm = false, paymentMethod = '' }) => {
  let mallList =  state.mallCartList.map(item => {
    return {
      mall_code: item.mall_code,
      mallTotalPrice: item.mallTotalPrice
    }
  })
  daEventCenter.triggerNotice({
    id: 'click_checkout.page_cart',
    data: {
      carts: carts.value,
      value,
      msg,
      scenes: 'conventional',
      cartInfo: cartInfo.value,
      mallShippingAnalysisParam: state.mallShippingAnalysisParam,
      promotionAnalysisParam: state.promotionState.cartPromotionAnalysis,
      isPlatForm: state.isPlatForm,
      mallList,
      showQuickShip: showQuickShipTag.value,
      reason: reason,
      prime_flag: '-',
      abtest: abtTest.value?.sa || '',
      button_name: +openControlLowPriceUser.value.interceptType ? openControlLowPriceUser.value.interceptType == 1 ? `lower_checkout_${openControlLowPriceUser.value.lowThresholdValue}` : `power_checkout_${openControlLowPriceUser.value.lowThresholdValue}` : 'checkout',
      click_from: 1,
      status: +openControlLowPriceUser.value.interceptType ? 0 : 1,
      coupon_satisfied: couponInfo.value?.cmpUsableCoupons?.length > 0 ? 1 : 0,
      ...(options || {}),
      ...(lowerPriceParams || {}),
      notSendTpm,
      payment_method: paymentMethod ? paymentFrontPaymentList.value.map(item => item.paymentCode.toLowerCase()).join(',') : 'normal',
      label_id: selectFilterLabel.value?.labelId || 'none',
      scene: scene,
    }
  })
}

const handleLoginEvent = async (needLoginStatus) => {
  emitInitCheckout({ value: 0, msg: 'Not login', reason: 1, scenes: needLoginStatus ? 'conventional' : 'guest' })
      
  await dispatch('handleCartLogin', { type: 'checkout' })
      
  clickCheckout()
}

const queryStock = (cb, needLoginStatus = true) => {
  if(!loginStatus.value && needLoginStatus) {
    handleLoginEvent(needLoginStatus)
    recordLog('2')
    return
  }
  if(state.lastCheckStockTime) {
    let { timeLimit = 120000, rowsLimit = 80 } = gbCommonInfo.CHECK_CART_STOCK_CONFIG || {}
    let now = new Date().getTime()
    let checkedCarts = carts.value.filter(item => item.is_checked == 1)
    if(timeLimit && rowsLimit && (now - state.lastCheckStockTime < timeLimit) && (checkedCarts.length <= rowsLimit)) { // 性能优化：时间戳差值小于120秒且勾选行数小于等于80则不检查库存接口
      cb && cb()
      return
    }
  }
  commit('changeRootSLoadingStatus', {
    show: true,
    maskTransparent: true,
    type: 'curpage',
  })
  checkStock({ needLoginStatus }).then( (res) => {
    commit('changeRootSLoadingStatus', {
      show: false,
    })
    if(res.code == 0){
      let outStockCarts = res?.info?.outStockCarts || []
      if(outStockCarts.length) {
        dispatch('checkSoldOut', { outStockCarts: outStockCarts })
        recordLog('3')
      } else {
        commit('updateState', { key: 'lastCheckStockTime', value: Date.now() })
      }
      cb && cb(outStockCarts.length)
    } else {
      if(res?.code == '10126002') {
        daEventCenter.triggerNotice({
          id: '1-8-2-13'
        })
        apiReport.report({
          apiPath: '/cart/checkout/billing_overrun'
        })
      }
      recordLog('3')
      emitInitCheckout({ value: 0, msg: res.msg, scenes: 'conventional' })
      res.msg && SToast(res.msg, 4000)
    }
  }).catch((err) => {
    commit('changeRootSLoadingStatus', {
      show: false,
    })
    // eslint-disable-next-line no-console
    console.log(err)
    cb && cb()
  })
}

const clickCheckout = ({ opt, paymentCode, foldPayment } = {}) => {
  const prefetchInfo = prefetchCheckoutInfo
  prefetchCheckoutInfo = null
  
  // 暂仅常规路径走预取
  goCheckout({ opt, prefetchInfo, paymentCode, foldPayment })
}

const goCheckout = ({ opt, prefetchInfo, paymentCode = '', foldPayment, next } = {}) => {
  if (window.toCheckout) return
  window.toCheckout = true
  web_checkout_button_click()
  if(!cartCheckedNum.value){
    SToast(state.cartInfo.checkedInterceptTip || language.value.SHEIN_KEY_PWA_20628, 3000)
    recordLog('1')
    return
  }
  handleDiscountDetails(false)
  if(isShowCartIncentiveProductList.value) {
    if (cartIncentiveProductListData.value?.type === 'credit_reward') scene = 'clubpop'
  }
  // 登录注册>闪购限购>限时折扣超限>打折超限 wiki.pageId=1137561716
  const validatorCallback = () => {
    if(cartInfo.value.interceptResultInfo) {
      const needClearFilter = () => {
        const items = cartInfo.value.interceptResultInfo?.promotionInterception?.popupNodeDataList?.flatMap(list => list?.nodeData?.flatMap(node => node?.productInfoList ?? [])) ?? []
        return items.some(item => !carts.value.find(cart => cart.mall_code === item.mall_code && cart.skuCode === item.product?.sku_code && cart.store_code === item.store_code))
      }
      if (needClearFilter()) {
      // 异步清空筛选状态
        CHECKOUT_REFETCH_CART_API.init()
        Promise.resolve(dispatch('clearFilter'))
          .finally(() => {
            CHECKOUT_REFETCH_CART_API.resolve()
          })
          .catch(() => { // 查车接口报错兜底异步场景,尝试异步再尝试一次
            dispatch('clearFilter')
          })
      }
      commit('updateState', { key: 'showPromotionInterception', value: true })
      recordLog('4')
      let promotionOver = {}
      let flashSaleLimit = []
      let discountSaleLimit = []
      carts.value.filter(cartItem => cartItem.is_checked && [10, 11, 24].includes(+cartItem.aggregateProductBusiness?.type_id) && cartItem.aggregateProductBusiness?.overLimit == 1).forEach(item => {
        if(!promotionOver[item.aggregateProductBusiness?.promotion_id]) {
          promotionOver[item.aggregateProductBusiness?.promotion_id] = true
          item.aggregateProductBusiness?.type_id == 24 ? discountSaleLimit.push({ reason: item.aggregateProductBusiness.singleOrTotalOver }) : flashSaleLimit.push({ flashSaleType: item.aggregateProductBusiness?.type_id == 11 ? 11 : item.aggregateProductBusiness.flash_type, reason: item.aggregateProductBusiness.singleOrTotalOver })
        }
      })
      if(flashSaleLimit.length) {
        daEventCenter.triggerNotice({
          id: '1-8-2-12',
          data: {
            is_flashsale: flashSaleLimit.map(item => item.flashSaleType).join(',')
          }
        })
      }
      const flashReasonList = flashSaleLimit.map(i => `${i.flashSaleType > 10 ? i.flashSaleType : 10}\`${i.flashSaleType > 10 ? '-' : i.flashSaleType}\`${i.reason > 2 ? 2 : i.reason}`)
      const limitReasonList = discountSaleLimit.map(i => `24\`-\`${i.reason}`)
      daEventCenter.triggerNotice({
        id: '1-8-2-22',
        data: {
          reason: [...flashReasonList, ...limitReasonList].join(',')
        }
      })
      emitInitCheckout({ value: 0, msg: 'over', reason: 3, paymentMethod: paymentCode })
          
      return false
    }
    let underPriceOverItem = carts.value.some(item => item.aggregateProductBusiness?.overLimit == 1 && item.aggregateProductBusiness?.type_id == 30)
    if(underPriceOverItem) {
      recordLog('7')
      SToast( getters['underPriceAbtValue'] ? language.value.SHEIN_KEY_PWA_23543 : language.value.SHEIN_KEY_PWA_23542, 3000)
      dispatch('clearFilter') // 清空筛选状态
      emitInitCheckout({ value: 0, msg: 'over', reason: 3 })
      return
    }

    return true
  }
  const validatorCallbackV2 = async () => {
    const needShow = () => !!cartInfo.value.interceptResultInfo?.promotionInterceptionV2
    const needClearFilter = () => {
      const itemIds = cartInfo.value.interceptResultInfo?.promotionInterceptionV2?.popupNodeDataList?.flatMap(group => group.nodeData.flatMap(node => node.cateIdList ?? []))
      return itemIds.some(id => !cartIds.value.includes(id))
    }
    if (abtInfo.value?.ActivityOver?.param?.ControlActivityOver == 'new') {
      if (needShow()) {
        if (needClearFilter()) {
          try {
            commit('updateState', { key: 'loadingShow', value: true })
            await dispatch('clearFilter')
            return await validatorCallbackV2()
          } catch {
            dispatch('clearFilter')
            return false
          } finally {
            commit('updateState', { key: 'loadingShow', value: false })
          }
        }
        recordLog('4')
        commit('updateState', { key: 'showPromotionInterceptionV2', value: true })
        emitInitCheckout({ value: 0, msg: 'over', reason: 3 })
        return false
      }
      return true
    }
    return validatorCallback()
  }
  queryStock(async (outStock) =>{
    if (!await validatorCallbackV2()) {
      return
    }
    if(outStock) {
      commit('updateState', {
        key: 'soldOutDialogShow',
        value: true
      })
      emitInitCheckout({ value: 0, msg: 'out of stock', reason: 2 })
    } else {
      if (openControlLowPriceUser.value.interceptType == 2) {
        recordLog('8')
        // 低客单强拦截
        emitInitCheckout({ value: 0, reason: 4, msg: 'UnArrived Low Price' })
        lowerPriceParams = {}
        commit('updateState', {
          key: 'showLowPriceUser',
          value: true
        })
      } else if (openControlLowPriceUser.value.interceptType == 1 && opt != 'lowerPriceUser') {
        recordLog('8')
        // 低客单弱拦截, opt避免二次弱拦截
        emitInitCheckout({ value: 0, reason: 4, msg: 'UnArrived Low Price' })
        lowerPriceParams = {}
        commit('updateState', {
          key: 'showLowPriceUser',
          value: true
        })
      } else {
        recordLog('0')
        const checkoutPath = `${ langPath }/checkout?_b=m${getAutoCouponCodes(mallCartBiData.value.autoUseCouponCodes)}${getPaymentCode(paymentCode)}${getFoldPayment(foldPayment)}`
        window.checkoutPending = prepareCheckoutPending({ prefetchInfo, checkoutPath, paymentCode, foldPayment })
        next ? next() : routerPush(checkoutPath)
        let simpleCart = []
        carts.value.forEach((item, index) => {
          let obj = {
            product: {
              goods_sn: item.product.goods_sn,
              goods_id: item.product.goods_id,
              productRelationID: item.product.productRelationID,
              brand: item.product.brand,
              cat_id: item.product.cat_id,
              sale_discount: item.product.sale_discount,
              size: item.product.size
            },
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            attr_value_en: item.attr_value_en,
            index: index
          }
          simpleCart.push(obj)
        })
        let tpmCacheCart = {
          carts: simpleCart,
          currency: gbCommonInfo.currency,
          totalPrice: totalPrice.value
        }

        let notSendTpm = true
        try{
          sessionStorage.setItem('tpmCacheCart', JSON.stringify(tpmCacheCart))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e)
          notSendTpm = false
        }
        emitInitCheckout({ value: 1, notSendTpm: notSendTpm })
      }
    }
  }, true)
}

const prepareCheckoutPending = ({ prefetchInfo, checkoutPath, paymentCode, foldPayment }) => {
  const goToCheckout = () => window.location.href = checkoutPath
  const resolver = res => {
    const { contextForSSR, redirectUrl, code } = res || {}
    if (redirectUrl) {
      window.location.href = code == '300206' || code == '00101110'
        ? `${langPath}/user/logout?redirection=${encodeURIComponent(checkoutPath)}`
        : redirectUrl + getFoldPayment(foldPayment, !redirectUrl.includes('?'))
      return
    }
    if (!contextForSSR) throw new Error('checkout contextForSSR missing')
    return res
  }
  const rejector = e => Promise.reject(e?.code === 'ERR_CANCELED' ? () => {} : goToCheckout)
  const newFetch = () => {
    prerenderCheckout.switchTaskState(true)
    return prerenderCheckout.getCache(`${paymentCode}${getFoldPayment(foldPayment)}`).then(resolver).catch(rejector)
  }
  const bindingCoupon = mallCartBiData.value.autoUseCouponCodes || checkoutInfo.value?.notBindCouponCodes
  prefetchInfo = prefetchInfo?.then(resolver).catch(rejector)

  return bindingCoupon
    // 绑券场景
    ? prefetchInfo
      ? prefetchInfo.then(res => ({ refreshTask: setCheckoutWithCouponCache().then(newFetch), ...res }))
      : setCheckoutWithCouponCache().then(newFetch)
    // 非绑券场景
    : prefetchInfo || newFetch()
}

const emitClickCheckoutEvt = ({ value = 0, msg = '', reason = '', options = {} }) => {
  emitInitCheckout({ value, msg, reason, options })
}
const goCheckoutEvt = ({ click_type = '', click_from = '', click_status = '' } = {}) => {
  if (click_type == 'lowerPriceUser') {
    lowerPriceParams = { click_from, status: click_status }
    clickCheckout({
      opt: click_type
    })
  }
}

const recordLog = (type) => {
  if(type != '0') {
    window.toCheckout = false
  }
  web_checkout_button_jump(type)
}

const getAutoCouponCodes = (autoUseCouponCodes) => {
  if (!autoUseCouponCodes) return ''
  return '&auto_coupon=' + autoUseCouponCodes
}

const getPaymentCode = (paymentCode) => {
  if (paymentCode) {
    return `&paymentCode=${paymentCode}`
  }
  return ''
}

const getFoldPayment = (needFoldPayment, firstParam) => {
  if (needFoldPayment) {
    if (firstParam) {
      return `?foldPayment=1` // 折叠支付方式列表
    } else {
      return `&foldPayment=1` // 折叠支付方式列表
    }
  }
  return ''
}

const setCheckoutWithCouponCache = async () => {
  try {
    const user = (await UserInfoManager.get({ key: 'UserInfo', actionType: 'userInfo' })) ?? {}
    const cacheData = {
      member_id: user?.member_id,
      login_time_last: user?.login_time_last,
      SiteUID: gbCommonInfo?.SiteUID,
      bind_coupon_list: checkoutInfo.value?.notBindCouponCodes || [],
      cart_optimal_coupon_list: mallCartBiData.value.autoUseCouponCodes ? mallCartBiData.value.autoUseCouponCodes.split(',') : []
    }
    return await setCache({
      scanType: 'checkoutWithCouponCache',
      cacheData,
      expire: 5 * 60 * 1000
    })
  } catch (error) {
  }
}

defineExpose({
  setPopoverTypeWithPriority,
  goCheckout
})
</script>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.vue-slide-layer-bottom-fade-enter-active,
.vue-slide-layer-bottom-fade-leave-active {
  transition: all 0.3s;
}

.vue-slide-layer-bottom-fade-enter-from,
.vue-slide-layer-bottom-fade-leave-active {
  transform: translate3d(0,100%,@tranlatez-transform1)!important; /* stylelint-disable-line declaration-no-important */
}
.c-cart-checkout{
  position: relative;
  .border-dpr(border-top,2px,#e5e5e5);
  background: #fff;
  overflow: hidden;
  width: 10rem;
}

/*
  normal Bar 动画 
*/
/* 进场动画 */
.normal-bar-enter-active {
  transition: all 0.3s;
  transform: translate3D(0, -100%, 0);
}
.normal-bar-enter-to {
  transform: translate3D(0, 0, 0);
}
/* 离场动画 */
.normal-bar-leave-from {
  position: absolute;
  left: 12/37.5rem;
  right:12/37.5rem;
  z-index: -1;
}
.normal-bar-leave-active {
  position: absolute;
  left: 12/37.5rem;
  right: 12/37.5rem;
  transform: translate3D(0, 0, 0);
  transition: all 0.3s;
}
.normal-bar-leave-to {
  transform: translate3D(0, -100%, 0);
}

/*
  pnpl Bar 动画 
*/
/* 进场动画 */
.bnpl-bar-enter-from {
  transform: translate3D(0, 100%, 0);
}
.bnpl-bar-enter-active {
  transition: all .3s;
}
.bnpl-bar-enter-to {
  transform: translateY(0);
}
/* 离场动画 */
.bnpl-bar-leave-active {
  position: absolute;
  left: 12/37.5rem;
  right: 12/37.5rem;
  transform: translate3D(0, 0, 0);
  transition: all 1s;
}
.bnpl-bar-leave-to {
  transform: translate3D(0, 100%, 0);
}

/**
 pnpl popover 动画
 */
.cart-checkout-bnpl__popover-wrap {
  height: 36/37.5rem;
  overflow: hidden;
}
.bnpl-popover-enter-from, .bnpl-popover-leave-to {
  height: 0;
  .cart-checkout-bnpl-popover {
    transform: translate3d(0, 100%, 0);
  }
}
.bnpl-popover-enter-active,
.bnpl-popover-leave-active, .cart-checkout-bnpl-popover {
  transition: all .2s;
}
.bnpl-popover-leave-active {
  position: absolute;
}
.bnpl-popover-enter-to,
.bnpl-popover-leave-from {
  height: 36/37.5rem;
  .cart-checkout-bnpl-popover {
    transform: translate3d(0, 0, 0);
  }
}
</style>
