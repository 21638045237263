import { computed, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { LOW_EFFICIENCY_GUIDE_RECOMMENDED } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import { useHideComponents } from 'public/src/pages/cart_v2/hooks/useHideComponents.js'

export function useRecommendGuideA() {
  const { state } = useStore()
  const { isHideComponent } = useHideComponents()
  const isShow = computed(() => state.showRecommendGuideA && !isHideComponent('LowEfficiencyGuideRecommended'))
  const { isActivePopover, deactivatePopover, activatePopover, registerPopover } = usePopoverManager({ type: LOW_EFFICIENCY_GUIDE_RECOMMENDED })
  registerPopover()
  const showRecommendGuideA = computed(() => {
    return isShow.value && isActivePopover.value
  })
  watch(() => isShow.value, (val) => {
    if (val) {
      activatePopover()
    }
  })

  watch(() => showRecommendGuideA.value, (val) => {
    if (!val) {
      deactivatePopover()
    }
  })

  return {
    showRecommendGuideA,
  }
}