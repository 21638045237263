<template>
  <div
    v-show="!batchActive"
    class="cart-footer j-cart-footer"
  >
    <ClientOnly>
      <!-- 券助手悬浮球 -->
      <div
        v-if="showCouponHelperEnter"
        v-show="!showDiscountDetails && !isShowCartIncentiveProductList && !isShowCartAssetsBenefitPop && !isShowCartFloorDrawer"
        class="c-coupon-helper-bubble j-coupon-helper-bubble"
        @click="openCouponHelperList"
      >
        <CouponHelperEntryBubble />
      </div>
      <transition name="recommend-guide-fade">
        <RecommendGuideA
          v-if="showRecommendGuideA"
          class="recommend-guide-a"
        />
      </transition>
      <CartPromotionCouponMix v-if="showNewPromoBar" />
      <CartPromotionBottom v-if="showOldPromoBar" />
      <CartRuleInfo
        v-if="showCartRuleInfo"
        :msg="overLimitTipOnBottom"
      />
      <IncentiveProductListDrawer v-if="isRenderCartIncentiveProductList" />
      <CartAssetsBenefitPop v-if="isRenderCartAssetsBenefitPop" />
      <CartPromotionInterception
        v-if="asyncComponentLoadedStatus.cartPromotionInterceptionDrawIsLoaded"
        :show-promotion-interception="showPromotionInterception"
        :promotion-interception="promotionInterception"
        :carts="state.carts"
        :language="language"
        :is-suggested="isSuggested"
        @closePromotionInterceptionDraw="closePromotionInterceptionDraw"
        @promotionInterceptionDrawMounted="promotionInterceptionDrawMounted"
      />
      <CartFloorDrawer v-if="asyncComponentLoadedStatus.cartFloorDrawerIsLoaded" />
      <CartNewGoodsRecommendDrawer v-if="asyncComponentLoadedStatus.cartNewGoodsRecommendDrawIsLoaded" />
      <CartAllDiscountDetailDrawer v-if="asyncComponentLoadedStatus.cartAllDiscountDetailDrawIsLoaded" />
      <CartBundlingDrawer v-if="asyncComponentLoadedStatus.cartBundlingDrawerIsLoaded" />
      <CartPromotionInterceptionV2 v-if="asyncComponentLoadedStatus.cartPromotionInterceptionV2DrawIsLoaded" />
    </ClientOnly>
    <CartCheckout ref="cartCheckoutVue" />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartFooter'
})
</script>
<script setup>
import { computed, ref, provide, defineExpose, defineProps, defineEmits, defineAsyncComponent } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import {
  usePopLoader,
  CART_INCENTIVE_PRODUCT_LIST,
  CART_ASSETS_BENEFIT_POP
} from 'public/src/pages/cart_v2/hooks/usePopLoader.js'

// component
import { ClientOnly } from '@sheinfe/vue-client-only'
import CartPromotionCouponMix from 'public/src/pages/cart_v2/components/cartPromotionCouponMix/index.vue'
import CartPromotionBottom from 'public/src/pages/cart_v2/components/cartPromotionBottom/index.vue'
import CartCheckout from 'public/src/pages/cart_v2/components/cartCheckout/CartCheckout.vue'
import CartRuleInfo from 'public/src/pages/cart_v2/components/CartRuleInfo/index.vue'
import CouponHelperEntryBubble from 'public/src/pages/cart_v2/components/couponHelper/EntryBubble.vue'
import RecommendGuideA from '@/public/src/pages/cart_v2/components/recommendGuide/RecommendGuideA.vue'
import { useRecommendGuideA } from '@/public/src/pages/cart_v2/components/recommendGuide/useRecommendGuideA.js'


const CartNewGoodsRecommendDrawer = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-new-goods-recommend-drawer" */'public/src/pages/cart_v2/components/cartNewGoodsRecommend/NewGoodsRecommendDrawer.vue'))
const CartAllDiscountDetailDrawer = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-discount-detail" */'public/src/pages/cart_v2/components/discountDetailDrawer/all/index.vue'))
const CartPromotionInterception = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-promotion-interception-dialog" */'public/src/pages/cart_v2/components/cartPromotionInterception/index.vue'))
const CartPromotionInterceptionV2 = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-promotion-interception-dialog-v2" */'public/src/pages/cart_v2/components/cartPromotionInterceptionV2/CartPromotionInterceptionV2.vue'))
const CartFloorDrawer = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-floor-drawer" */'public/src/pages/cart_v2/components/cartFloorDrawer/CartFloorDrawer.vue'))
const IncentiveProductListDrawer = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-incentive-product-list-drawer" */'public/src/pages/cart_v2/components/incentiveProductListDrawer/IncentiveProductListDrawer.vue'))
const CartAssetsBenefitPop = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-benefit-pop" */'public/src/pages/cart_v2/components/cartBenefitPop/CartAssetsBenefitPop.vue'))
const CartBundlingDrawer = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-cart-bundling-drawer" */'public/src/pages/cart_v2/components/cartBundlingDrawer/CartBundlingDrawer.vue'))

const { isRender: isRenderCartIncentiveProductList, isShow: isShowCartIncentiveProductList } = usePopLoader({ name: CART_INCENTIVE_PRODUCT_LIST })
const { isRender: isRenderCartAssetsBenefitPop, isShow: isShowCartAssetsBenefitPop } = usePopLoader({ name: CART_ASSETS_BENEFIT_POP })

const { state, getters, commit } = useStore()
const { showRecommendGuideA } = useRecommendGuideA()

defineProps({
  showCouponHelperEnter: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['open-coupon-helper-list'])

let cartCheckoutVue = ref(null)

const showNewPromoBar = computed(() => getters['showNewPromoBar'] && !batchActive.value)
const showOldPromoBar = computed(() => getters['showOldPromoBar'] && !batchActive.value)

const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show) // 整车优惠明细抽屉
const isShowCartFloorDrawer = computed(() => state.isShowCartFloorDrawer) // 楼层抽屉
const showPromotionInterception = computed(() => state.showPromotionInterception) // 超限抽屉 old
const showPromotionInterceptionV2 = computed(() => state.showPromotionInterceptionV2) // 超限抽屉 new

const showPriceChangePopover = computed(() => state.priceChangeState.showPopover)
const overLimitTipOnBottom = computed(() => {
  return state.checkoutState.overLimitTipOnBottom
})
const showCartRuleInfo = computed(() => state.locals.lang == 'br' && state.cartAbtInfo?.brTaxhint?.param?.isSwitchOn == '1' && overLimitTipOnBottom.value)
const asyncComponentLoadedStatus = computed(() => state.asyncComponentLoadedStatus)
const promotionInterception = computed(() => state.cartInfo.interceptResultInfo?.promotionInterception)
const isSuggested = computed(() => getters['isSuggested'])
const language = computed(() => state.language)
const batchActive = computed(() => state.batchActive)

const closePromotionInterceptionDraw = () => {
  commit('updateState', { key: 'showPromotionInterception', value: false })
}
const promotionInterceptionDrawMounted = () => {
  commit('updateState', { key: 'loadingShow', value: false })
}
const openCouponHelperList = () => {
  emit('open-coupon-helper-list')
}
const fixedUptt = () => {
  if (typeof window === 'undefined') return
  setTimeout(() => {
    // 解决1.5s内离开购物车页面，异常设置bottom的问题
    if (state.cartLeave) return
    const bottomH = document.querySelector('.j-cart-footer')?.clientHeight
    const upttDom = document.querySelector('.j-uptt')
    if(upttDom){
      upttDom.style.bottom = `calc(${bottomH + 20}px - env(safe-area-inset-bottom))`
    }
  }, 1500)
}
provide('fixedUptt', fixedUptt)
defineExpose({
  cartCheckoutVue: cartCheckoutVue.value,
  showOldPromoBar
})
</script>
<style lang="less" scoped>
.cart-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 10rem;
  z-index: 19; /* stylelint-disable-line declaration-property-value-blacklist */

  .cart-checkout {
    position: relative;
    .border-dpr(border-top,2px,#e5e5e5);
    background: #fff;
    padding: 16/75rem 24/75rem;
    overflow: hidden;
    z-index: 99;
  }
  .c-coupon-helper-bubble {
    max-width: 92%;
    position: absolute;
    top: -1.09333rem;
    left: 0.2rem;
    margin-top: -0.415rem;
    margin-left: .2rem;
    transition: max-width linear .5s;
    span{
      font-weight: bold;
      display: inline-block;
      .margin-l(-.18rem);
      .margin-r(.24rem);
      transition: all linear .5s;
    }
    &.bubble-fold{
      max-width: 64px;
      [data-dpr='1'] & {
        max-width: 64px * 0.5;
      }
      [data-dpr='3'] & {
        max-width: 64px * 1.5;
      }
      span{
        color: transparent;
      }
    }
  }
}
.recommend-guide-a {
  position: absolute;
  top: -116/75rem;
}
/* 低效用户推荐指引气泡动画 */
.recommend-guide-fade-enter-active,
.recommend-guide-fade-leave-active {
  transition: opacity 300ms,
}
.recommend-guide-fade-enter-from,
.recommend-guide-fade-leave-to {
  opacity: 0;
}
</style>
