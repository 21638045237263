<template>
  <div
    class="cart-bar-normal"
    :class="{
      'new-bar': cartBottomStyle,
    }"
  >
    <CartCheckoutAll v-if="!cartBottomStyle" />
    <div class="cart-bar-normal__middle">
      <CartCheckoutPrice
        :is-right="!cartBottomStyle"
        :show-type="cartPriceShowType"
        :is-opened="!!showDiscountDetails"
        @click="handleDiscountDetails(!showDiscountDetails, 'click-save')"
      />
      <CartPriceLure
        v-if="cartBottomStyle"
      />
    </div>
    <component
      :is="popoverName"
      :show-filter-label-popover="showFilterLabelPopover"
      @set-popover-type="setPopoverTypeWithPriority"
    >
      <s-button
        v-tpes:cart
        :type="['primary','H80PX']"
        tabindex="0"
        :min-width="cartBottomStyle ? '100%' : ''"
        :max-width="!cartBottomStyle ? (362/75).toFixed(5)+'rem' : ''"
        role="button"
        :class="[
          'fsp-element',
          'incentive-button',
          {
            'incentive-button_one-line': !isShowCheckoutButtonIncentiveText,
          }
        ]"
        style="text-transform: uppercase;"
        fsp-key="cart_checkout_btn"
        :style="{fontSize: fontSize}"
        :limit-duration="10"
        @pointerover="prefetchCheckout(1)"
        @pointerdown="prefetchCheckout(2)"
        @click.stop="clickCheckout"
      >
        <div class="checkout-btn-content">
          <TextEllipsis
            :content="checkoutBtnText"
            :rows="rows"
            :min-size="11"
            :observerDOM="true"
            position="middle"
            style="text-transform: none;"
          >
            <template
              v-if="isShowCheckoutBtnQuantity"
              #end
            >
              <span class="checkout-num">{{ checkoutBtnQuantity }}</span>
            </template>
          </TextEllipsis>
        </div>
        <CheckoutButtonIncentiveText
          class="not-fsp-element"
          :controlCheckoutNameText="controlCheckoutNameText"
          :is-show="isShowCheckoutButtonIncentiveText"
        />
      </s-button>
      <AutoStretchSwiperTag
        class="cart-bar-normal__swiper-tag"
        :show-free-shipping-tag="showNewFreeShipping"
        :show-quick-ship-tag="showQuickShip"
        stretch-direction="rtl"
        :language="language"
      />
    </component>
  </div>
</template>
<script>
/**
 * @description 购物车/结算BAR/普通结算
 */
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartBarNormal',
})
</script>
<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { computed, defineEmits } from 'vue'
import CartCheckoutAll from './CartCheckoutAll.vue'
import CartCheckoutPrice from './CartCheckoutPrice.vue'
import CheckoutIncentivePopover from './CheckoutIncentivePopover.vue'
import CheckoutFilterPopover from './CheckoutFilterPopover.vue'
import AutoStretchSwiperTag from './AutoStretchSwiperTag.vue'
import CheckoutButtonIncentiveText from 'public/src/pages/cart_v2/components/cartCheckoutIncentive/CheckoutButtonIncentiveText.vue'
import CartPriceLure from './CartPriceLure.vue'
import { useStore } from '../../hooks/useStore'
import { tpes as vTpes } from 'public/src/services/TPM/tpes.js'
import TextEllipsis from '../common/TextEllipsis.vue'
const { state, getters } = useStore()

const emit = defineEmits(['set-popover-type'])

const props = defineProps({
  handleDiscountDetails: {
    type: Function,
    required: true,
  },
  showFilterLabelPopover: {
    type: String,
    required: true,
  },
  prefetchCheckout: {
    type: Function,
    required: true,
  },
  fontSize: {
    type: String,
    required: true,
  },
  checkedQuantity: {
    type: null,
    required: true,
  },
  controlCheckoutNameText: {
    type: null,
    required: true,
  },
  showNewFreeShipping: {
    type: [Boolean, String],
    default: false,
  },
  clickCheckout: {
    type: Function,
    required: true,
  },
  language: {
    type: Object,
    required: true,
  },
  showQuickShip: {
    type: [Boolean, String],
    default: false,
  },
})
const cartBottomStyle = computed(() => getters.cartBottomStyle)
const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show)
const popoverName = computed(() => props.showFilterLabelPopover == 'filter' ? CheckoutFilterPopover : CheckoutIncentivePopover)
const isSuggested = computed(() => getters['isSuggested'])
const cartPriceShowType = computed(() => {
  if (cartBottomStyle.value && !isSuggested.value) {
    return 'normal'
  }
  return 'suggest'
})
const showCheckoutBtnLureInfo = computed(() => {
  if(props.controlCheckoutNameText) return ''
  return true
})
const checkoutBtnLureInfo = computed(() => {
  return state.checkoutState.cartLureInfo?.checkoutBtnLureInfo
})
const isShowCheckoutButtonIncentiveText = computed(() => !!(showCheckoutBtnLureInfo.value && checkoutBtnLureInfo.value))

const rows = computed(() => isShowCheckoutButtonIncentiveText.value ? 1 : 2)

const checkoutBtnText = computed(() => props.controlCheckoutNameText || state.checkoutState.checkoutInfo?.checkoutBtnText || props.language.SHEIN_KEY_PWA_15062)
const checkoutBtnQuantity = computed(() => {
  if (state.checkoutState.checkoutInfo?.checkoutBtnQuantity) return state.checkoutState.checkoutInfo.checkoutBtnQuantity

  if (props.checkedQuantity) return `(${props.checkedQuantity})`

  return ''
})
const isShowCheckoutBtnQuantity = computed(() => !props.controlCheckoutNameText && !!checkoutBtnQuantity.value)

const setPopoverTypeWithPriority = (type) => {
  emit('set-popover-type', type)
}
</script>

<style lang="less">
.cart-bar-normal {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  padding: 16/75rem 24/75rem;
  &:not(.new-bar) {
    .cart-bar-normal__middle {
      flex: 1;
      overflow: hidden;
      max-width: 100%;
    }
  }
  &__middle {
    display: flex;
    flex-direction: column;
  }
  &.new-bar {
    justify-content: space-between;
    --around-padding: 0.267rem;
    padding: 16/75rem 20/75rem;
    .cart-bar-normal__middle {
      margin-right: 16/75rem;
    }
    .checkout-filter-popover, .checkout-incentive-popover {
      margin-left: 16/75rem;
    }
    .checkout-filter-popover, .checkout-incentive-popover, .cart-bar-normal__middle {
      max-width: 50%;
      width: calc(50% - 0.213rem);
      flex-shrink: 0;
      flex-grow: 0;
    }
    .checkout-btn-contain {
      width: 100%;
    }
    .checkout-btn-content {
      margin-left: 20/75rem;
      margin-right: 20/75rem;
    }
  }

  .sui-checkbox {
    margin-right: 5/37.5rem !important;/* stylelint-disable-line declaration-no-important */
    .sui-checkbox__label-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap
    }
  }
  .cart-checkout__price-wrapper {
    margin-right: 12/75rem;
    flex: 1;
    text-align: right;
    overflow: hidden;
    max-width: 100%;
  }
  .cart-bar-normal__swiper-tag {
    position: absolute;
    right: -10/75rem;
    top: -14/75rem;
    .font-dpr(16px);
    &::after{
      content: "";
      width: 0;
      height: 0;
      border-color: #16533A transparent;
      border-style: solid;
      position: absolute;
      top: 100%;
      display: block;
      border-width: 10/75rem 10/75rem 0 0;
      right: 1px;
    }
  }
}
</style>

<style lang="less" scoped>
.incentive-button {
  position: relative;
  padding: 0 !important;/* stylelint-disable-line declaration-no-important */
  line-height: normal !important;/* stylelint-disable-line declaration-no-important */
  gap: 4/74rem;
  &_one-line {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
.checkout-btn-content {
  display: inline-flex !important;/* stylelint-disable-line declaration-no-important */
  justify-content: center;
  margin: 0 .32rem;
  width: fit-content;
  max-height: 26 / 37.5rem;
  .checkout-txt{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    box-sizing: border-box;
    text-transform: none;
  }
  .checkout-num{
    flex: unset;
    text-transform: unset;
  }
}
</style>
