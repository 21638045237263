<template>
  <div class="cart-nav__all">
    <CartCheckoutAll
      v-if="!batchActive"
      :marginRight="marginRight"
    />
    <CartBatchAll
      v-else
      :marginRight="marginRight"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import CartBatchAll from 'public/src/pages/cart_v2/components/cartBatchFooter/CartBatchAll.vue'
import CartCheckoutAll from 'public/src/pages/cart_v2/components/cartCheckout/CartCheckoutAll.vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

defineProps({
  marginRight: {
    type: String,
    default: '0.4rem'
  }
})

const { state } = useStore()
const batchActive = computed(() => state.batchActive)
</script>

<style lang="less">
.cart-nav__all {
  font-size: 0;
  height: 24px;
}
</style>
