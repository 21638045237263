<template>
  <div class="cart-batch__all">
    <SCheckbox
      :model-value="checkAll"
      :gap="marginRight"
      label="all"
      @change="handleChange"
    >
      <span class="cart-batch__all-text">{{ language.SHEIN_KEY_PWA_15623 }}</span>
    </SCheckbox>
  </div>
</template>

<script setup>
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { computed, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { daEventCenter } from 'public/src/services/eventCenter'

defineProps({
  marginRight: {
    type: String,
    default: '0.4rem'
  }
})
let timeoutCheckAll = null

const { state, commit } = useStore()
const carts = computed(() => state.carts)
const language = computed(() => state.language)
const hasNewcomerItem = computed(() => carts.value.some(item => item.isNewcomerItem))
const batchEditItemIds = computed(() => state.batchEditItemIds)
const checkAll = computed(() => {
  const cartsLength = carts.value.filter(f => !f.isNewcomerItem).length
  const batchedItemsLength = batchEditItemIds.value.length
  return cartsLength == batchedItemsLength
})

watch(() => checkAll.value, n => {
  if (n) {
    daEventCenter.triggerNotice({
      daId: '1-8-7-17',
      extraData: {
        carts: carts.value
      }
    })
  }
})

const handleChange = () => {
  // checkbox在不同浏览器上有时会触发两次 只能延迟执行
  if (timeoutCheckAll) return
  timeoutCheckAll = setTimeout(() => {
    if (checkAll.value) {
      commit('updateState', {
        key: 'batchEditItemIds',
        value: []
      })
    } else {
      if (hasNewcomerItem.value) {
        SToast(language.value.SHEIN_KEY_PWA_26189)
        daEventCenter.triggerNotice({ daId: '1-8-3-35' })
      }
      const itemIds = carts.value.filter(f => !f.isNewcomerItem).map(item => item.id)
      commit('updateState', {
        key: 'batchEditItemIds',
        value: itemIds || []
      })
    }
    window.clearTimeout(timeoutCheckAll)
    timeoutCheckAll = null
  }, 10)
}
</script>

<style lang="less">
.cart-batch {
  &__all-text {
    color: var(--nav-bar-color, #222);
  }
}
</style>
