<template>
  <RowMarquee
    v-if="isShow && isMarquee"
    ref="marqueeRef"
    manual
    gap="12px"
  >
    <CheckoutButtonIncentiveTextContent :checkout-btn-lure-info="checkoutBtnLureInfo" />
  </RowMarquee>
  <CheckoutButtonIncentiveTextContent
    v-else-if="isShow && !isMarquee"
    overflow
    :checkout-btn-lure-info="checkoutBtnLureInfo"
  />
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useStore } from '../../hooks/useStore'
import RowMarquee from 'public/src/pages/cart_v2/components/common/RowMarquee.vue'
import CheckoutButtonIncentiveTextContent from './CheckoutButtonIncentiveTextContent.vue'

const props = defineProps({
  controlCheckoutNameText: {
    type: String,
    default: ''
  },
  clone: Boolean,
  isShow: {
    type: Boolean,
    default: false
  }
})

const { state } = useStore()
const checkoutBtnLureInfo = computed(() => {
  return state.checkoutState.cartLureInfo?.checkoutBtnLureInfo
})
const isMarquee = computed(() => state.cartAbtInfo?.IncentiveInform?.param?.NewButtonText === 'on')

const marqueeRef = ref()
watch(() => [isMarquee, props.isShow], async () => {
  if (typeof window === 'undefined') return
  if (!isMarquee.value || !props.isShow) return
  await nextTick()
  marqueeRef.value?.start()
}, { immediate: true })
</script>

<style>

</style>
