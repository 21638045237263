<template>
  <div 
    class="couponp-progress"
    :style="{
      height: transformRem(height),
      'background-color': _bgColor,
    }"
  >
    <div
      class="couponp-progress-bg"
      :style="{ 
        height: transformRem(height),
        width: `${value}%`,
        'background-color': _color,
      }"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    height: {
      type: [String, Number],
      default: 3
    },
    value: {
      type: [String, Number],
      default: 0
    },
    color: {
      type: String,
      default: '#222',
    },
    bgColor: {
      type: String,
      default: '#DDD'
    },
  },
  computed: {
    _color() {
      return this.color
    },
    _bgColor() {
      return this.bgColor
    }
  },
  methods: {
    transformRem(num) {
      return num * 2 / 75 + 'rem'
    }
  },
})
</script>

<style lang="less" scoped>
  .couponp-progress {
    width: 100%;
    border-radius: 2px;
    .couponp-progress-bg {
      border-radius: 2px;
      transition: width .5s linear;
    }
  }
</style>
